<template>
    <section class="stepThree">
        <div class="container">
            <div class="titleWrap">
                <img src="../assets/images/LOGO_NRG.png" alt="NRG awareness logo"/>
                <h1>Finally, type in your details and we will send you a report together with our proposed solution for
                    your project!</h1>
            </div>
            <form>
                <div class="customCard">
                    <div class="formPart">
                        <h2 class="text-center text-primary pb-5">Step 3</h2>
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-6">
                                <label for="name" class="form-label">
                                    Name
                                </label>
                            </div>
                            <div class="col-sm-6 pt-3 pt-sm-0">
                                <input type="text" class="form-control" id="name" name="name" v-model="name" required>
                            </div>
                            <div class="col-12 mt-2" v-if="nameError">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row pt-3 d-flex align-items-center">
                            <div class="col-sm-6">
                                <label for="family-name" class="form-label">
                                    Surname
                                </label>
                            </div>
                            <div class="col-sm-6 pt-3 pt-sm-0">
                                <input type="text" class="form-control" id="family-name" name="family-name"
                                       v-model="lastName" required>
                            </div>
                            <div class="col-12 mt-2" v-if="lastNameError">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row pt-3 d-flex align-items-center">
                            <div class="col-sm-6">
                                <label for="email" class="form-label">
                                    Email
                                </label>
                            </div>
                            <div class="col-sm-6 pt-3 pt-sm-0">
                                <input type="text" class="form-control" id="email" name="email" v-model="email"
                                       required>
                            </div>
                            <div class="col-12 mt-2" v-if="emailError">
                                <p class="alert-danger p-2">{{ errorEmail }}</p>
                            </div>
                        </div>
                        <div class="row pt-5">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <input class="form-check-input" type="checkbox" name="privacy" v-bind:true-value=1
                                           v-bind:false-value=0
                                           aria-label="Unknown Surface Area" id="privacyCheck" v-model="privacy">
                                    <label for="privacyCheck" class="lighterLabel ps-3">
                                        I agree that this application stores my information, in accordance with <a
                                        href="https://www.nrgawareness.com/privacy-policy/">Privacy policy</a>
                                        and <a href="https://www.nrgawareness.com/terms-conditions/">Terms &
                                        Conditions,</a> allowing me to receive an emailed heat loss report and product
                                        information based on my inputs into the calculator.</label>
                                </div>
                                <div class="row">
                                    <div class="col-12 mt-2" v-if="privacyError">
                                        <p class="alert-danger p-2">{{ errorCheck }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pt-3 pt-sm-0">
                                <div class="d-flex">
                                    <input class="form-check-input" type="checkbox" name="promotion" v-bind:true-value=1
                                           v-bind:false-value=2
                                           aria-label="Unknown Surface Area" id="promotion" v-model="promotionHelp">
                                    <label for="promotion" class="lighterLabel ps-3">
                                        I agree that NRG Awareness will use my email address to send me technical and
                                        promotional material in the future.</label>
                                </div>
                                <div class="row">
                                    <div class="col-12 mt-2" v-if="gdprError">
                                        <p class="alert-danger p-2">{{ errorCheck }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttonWrap">
                    <button class="btn btn-primary" type="submit" @click.prevent="finish">Request a report</button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import store from "@/store";
import router from "@/router";
import axios from "axios";
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

export default {
    setup() {
        const toast = () => {
            createToast('')
        }
        return {toast}
    },
    data() {
        return {
            name: "",
            lastName: "",
            email: "",
            privacy: 1,
            promotion: true,
            promotionHelp: 1,

            nameError: false,
            lastNameError: false,
            emailError: false,
            privacyError: false,
            gdprError: false,

            error: "This field is required",
            errorEmail: 'Please enter a valid email address',
            errorCheck: 'Please check this field',

            //eslint-disable-next-line
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,


            data: null,

            roomData: null,

            showTable: false,

            sumArea: null,
            actualInternalCalc: [],
            internalExternalWallDiff: 0,
            differentialTemp: [],

            roomCube: [],
            actualTempDif: [],
            airHeatReq: [],

            totalInternalWalls: [],
            totalExternalWalls: [],

            glazedWindows: [],
            glazedDoors: [],
            totalGlazedArea: [],
            roofLights: [],

            insideWall: [],
            outsideWall: [],

            windowsLoss: [],
            floor: [],
            roof: [],
            outsideDoor: [],
            fluesAndOpenings: [],

            radiatorDeltaT: [],
            correctionFactor50: [],
            correctionFactor60: [],
            totalIncl: [],
            watts: [],
            flowRate: [],

            sumTotalInc: null,
            sumFlowRate: null,

            unique: [],
            sumByZoneIncl: [],
            byZoneFlowRate: [],
            areaByZone: [],

            sumFlowTemp: 0,
            sumReturnTemp: null,
            sumWattsMeter: null,

            jetPlano: [],
            pipeCentres: [],

            dataForSending: [],
            message: "",

            zones: [],

        }
    },
    methods: {
        dataFormat() {
            const rooms = JSON.parse(JSON.stringify(this.roomData)).map((r, index) => {
                return {
                    roomType: this.roomData[index].chosenRoom,
                    name: this.roomData[index].roomLabel,
                    surfaceArea: this.roomData[index].surfaceArea,

                    flowRate: this.flowRate[index],
                    heatLoss: this.totalIncl[index],
                    heatLossPerSquaredM: this.watts[index],
                    correctionFactor50: this.correctionFactor50[index],
                    correctionFactor60: this.correctionFactor60[index],
                    jetPlano: this.jetPlano[index],
                    recommendedPipes: this.pipeCentres[index],
                    selectedZone: r.selectedZone,

                    userInput: {
                        windowsArea: this.glazedWindows[index] || 0,
                        doorArea: this.glazedDoors[index] || 0,
                        roofLightsArea: this.roofLights[index] || 0,
                        internalWalls: this.totalInternalWalls[index] || 0,
                        externalWalls: this.totalExternalWalls[index] || 0,
                        ventilation: {
                            chimneys: this.data.roomData[index].chimneys || 0,
                            openFlues: this.data.roomData[index].openFlues || 0,
                            fans: this.data.roomData[index].extractionFans || 0,
                        },
                        height: this.data.roomData[index].height || 0,
                        heatedSpaceAbove: this.data.roomData[index].heatedSpaceAbove,
                        heatedSpaceBelow: this.data.roomData[index].heatedSpaceBelow,
                        buildingAge: this.data.roomData[index].selectedAge || 0,
                        airChange: this.data.roomData[index].airChange || 0,
                    }
                };
            });

            const _ = require('lodash');
            this.zones = _.uniqBy(rooms.map(function (r) {
                return r.selectedZone;
            }), "name").map((zone, i) => {
                return {
                    ...zone,
                    sumByZoneIncl: this.sumByZoneIncl[i],
                    byZoneFlowRate: this.byZoneFlowRate[i],
                    areaByZone: this.areaByZone[i],
                    rooms: rooms.filter(function (r) {
                        return r.selectedZone.name === zone.name;
                    })
                }
            })

        },
        finish() {
            if (this.name && this.lastName && this.reg.test(this.email) && this.privacy) {
                createToast('Report is sending...',
                    {
                        position: 'bottom-right',
                        timeout: 3000,
                        toastBackgroundColor: 'green'
                    })
                if (this.promotionHelp === 1) {
                    this.promotion = true;
                } else {
                    this.promotion = false;
                }
                this.dataForSending = {
                    name: this.name,
                    lastName: this.lastName,
                    promotion: this.promotion,
                    projectName: this.data.projectName,
                    pickedSource: this.data.pickedSource,
                    email: this.email,
                    hotWaterZone: this.data.hotWaterZone,
                    outsideTemp: this.data.outsideTemp,
                    sumArea: this.sumArea,
                    sumFlowTemp: this.sumFlowTemp,
                    sumReturnTemp: this.sumReturnTemp,
                    sumWattsMeter: this.sumWattsMeter,
                    sumFlowRate: this.sumFlowRate,
                    sumTotalInc: this.sumTotalInc,
                    jetPlano: this.jetPlano,
                    pipeCentres: this.pipeCentres,
                    zones: this.zones,
                }
                axios.post("api/result", this.dataForSending)
                    .then(() => {
                        store.dispatch('savePersonalInfo',
                            {name: this.name, lastName: this.lastName, email: this.email, promotion: this.promotion}
                        );
                        router.push({name: 'report'});
                    })
                    .catch(() => {
                        createToast('Oops! Something is wrong. Please try again.',
                            {
                                position: 'bottom-right',
                                timeout: 5000,
                                toastBackgroundColor: 'red'
                            })
                    });
            } else {
                if (this.name) {
                    this.nameError = false;
                } else {
                    this.nameError = true;
                }

                if (this.lastName) {
                    this.lastNameError = false;
                } else {
                    this.lastNameError = true;
                }

                if (this.reg.test(this.email)) {
                    this.emailError = false;
                } else {
                    this.emailError = true;
                }

                if (this.privacy) {
                    this.privacyError = false;
                } else {
                    this.privacyError = true;
                }
            }
        }
    },
    mounted() {
        if (store.getters.getAllData) {
            this.data = store.getters.getAllData;
            this.lastName = this.data.lastName;
            this.name = this.data.name;
            this.email = this.data.email;
        }

        this.roomData = this.data.roomData;
        this.roomData = this.roomData.sort((a, b) => (a.selectedZone.name > b.selectedZone.name ? -1 : 1));


        let rooms = this.data.roomData.map(function (room) {
            return room.chosenRoom;
        })

        let temps = rooms.map(function (data) {
            return data.temp;
        })

        let allAreas = this.data.roomData.map(function (data) {
            return data.surfaceArea;
        })

        this.sumArea = allAreas.reduce((a, b) => a + b, 0);

        for (let i = 0; i < this.data.roomData.length; i++) {
            this.actualInternalCalc.push(temps[i] * allAreas[i]);
        }
        this.internalExternalWallDiff = (this.actualInternalCalc.reduce((a, b) => a + b, 0) / this.sumArea);

        for (let i = 0; i < this.data.roomData.length; i++) {
            let reducedWindows = this.data.roomData[i].windows.reduce((a, b) => a + b, 0);
            this.glazedWindows.push(reducedWindows);
            this.totalGlazedArea.push(this.glazedWindows[i]);
            let reducedDoors = this.data.roomData[i].doors.reduce((a, b) => a + b, 0);
            this.glazedDoors.push(reducedDoors);
            this.totalGlazedArea[i] += this.glazedDoors[i];

            let tempRoofLights = this.data.roomData[i].doors.reduce((a, b) => a + b, 0);
            this.roofLights.push(tempRoofLights);

            let tempInternal = this.data.roomData[i].internalWalls.reduce((a, b) => a + b, 0);
            this.totalInternalWalls.push(tempInternal);
            let tempExternal = this.data.roomData[i].externalWalls.reduce((a, b) => a + b, 0);
            this.totalExternalWalls.push(tempExternal);

            this.roomCube.push(this.data.roomData[i].height * allAreas[i]);
            this.differentialTemp.push(temps[i] - this.internalExternalWallDiff);
            this.actualTempDif.push(temps[i] - this.data.outsideTemp);
            this.airHeatReq.push(this.roomCube[i] * this.data.roomData[i].airChange * this.actualTempDif[i] * 0.33);

            this.insideWall.push(this.totalInternalWalls[i] * this.data.roomData[i].height * (temps[i] - this.internalExternalWallDiff));
            this.outsideWall.push((this.totalExternalWalls[i] * this.data.roomData[i].height - this.totalGlazedArea[i]) * this.actualTempDif[i] * this.data.roomData[i].selectedAge.wall);

            this.windowsLoss.push((this.glazedWindows[i] * this.data.roomData[i].selectedAge.windows) * this.actualTempDif[i]);

            if (this.data.roomData[i].heatedSpaceBelow === "true") {
                this.floor.push(0);
            } else {
                this.floor.push((allAreas[i] * this.data.roomData[i].selectedAge.floor) * this.actualTempDif[i])
            }

            if (this.data.roomData[i].heatedSpaceAbove === "true") {
                this.roof.push(0);
            } else {
                this.roof.push(((allAreas[i] - this.roofLights[i]) * this.data.roomData[i].selectedAge.roof) * this.actualTempDif[i])
            }

            if (this.data.roomData[i].doors.length < 1) {
                this.outsideDoor.push(0);
            } else {
                this.outsideDoor.push(this.glazedDoors[i] * this.data.roomData[i].selectedAge.doors * this.actualTempDif[i]);
            }
            this.fluesAndOpenings.push((40 * this.data.roomData[i].chimneys + 20 * this.data.roomData[i].openFlues + 10 * this.data.roomData[i].extractionFans) * 0.33 * this.actualTempDif[i]);

            this.radiatorDeltaT.push((this.data.roomData[i].selectedZone.heatEmitter.flow + this.data.roomData[i].selectedZone.heatEmitter.return) / 2 - temps[i]);
            this.correctionFactor50.push(Math.pow(this.radiatorDeltaT[i] / 50, 1.3));
            this.correctionFactor60.push(Math.pow(this.radiatorDeltaT[i] / 60, 1.3));
            let temp = this.airHeatReq[i] + this.outsideWall[i] + this.insideWall[i] + this.windowsLoss[i] + this.floor[i] + this.roof[i] + this.outsideDoor[i] + this.fluesAndOpenings[i];
            this.totalIncl.push(temp + (temp * 0.1));
            this.watts.push(this.totalIncl[i] / allAreas[i]);
            this.flowRate.push((this.totalIncl[i] / (Math.abs(this.data.roomData[i].selectedZone.heatEmitter.flow - this.data.roomData[i].selectedZone.heatEmitter.return) * 4181)));

            if (this.sumFlowTemp < this.data.roomData[i].selectedZone.heatEmitter.flow) {
                this.sumFlowTemp = this.data.roomData[i].selectedZone.heatEmitter.flow;
            }

            this.jetPlano.push(this.totalIncl[i] / (this.correctionFactor50[i] * 115.4));

            if (this.watts[i] < 60) {
                this.pipeCentres.push("150mm")
            } else if (this.watts[i] >= 60 && this.watts[i] < 100) {
                this.pipeCentres.push("100mm")
            } else if (this.watts[i] >= 100) {
                this.pipeCentres.push("Heat load too high for UFH, supplementary heat emitter(s) needed")
            }
        }

        this.sumTotalInc = this.totalIncl.reduce((a, b) => a + b, 0);
        //this.sumFlowRate = this.flowRate.reduce((a, b) => a + b, 0);

        this.sumWattsMeter = this.sumTotalInc / this.sumArea;

        this.unique = [...new Set(this.roomData.map(item => item.selectedZone.name))];

        let helpReturn = [];
        for (let i = 0; i < this.unique.length; i++) {
            this.sumByZoneIncl.push(0);
            this.byZoneFlowRate.push(0);
            this.areaByZone.push(0);
            for (let j = 0; j < this.roomData.length; j++) {
                if (this.unique[i] === this.roomData[j].selectedZone.name) {
                    this.sumByZoneIncl[i] += this.totalIncl[j];
                    this.byZoneFlowRate[i] += this.flowRate[j];
                    this.areaByZone[i] += this.roomData[j].surfaceArea;
                    helpReturn[i] = this.roomData[j].selectedZone.heatEmitter.return;
                }
            }
        }

        let upperFraction = 0;
        let bottomFraction = 0;
        for (let i = 0; i < this.sumByZoneIncl.length; i++) {
            upperFraction += (this.sumByZoneIncl[i] / ((this.sumFlowTemp - helpReturn[i]) * 4186)) * helpReturn[i];
            bottomFraction += this.sumByZoneIncl[i] / ((this.sumFlowTemp - helpReturn[i]) * 4186);
        }

        this.sumReturnTemp = upperFraction / bottomFraction;

        this.sumFlowRate = this.sumTotalInc / (Math.abs(this.sumFlowTemp - this.sumReturnTemp) * 4181);

        this.dataFormat();
    }
}
</script>

<style lang="scss" scoped>
.stepThree {
    padding: 60px 0;

    .titleWrap {

        img {
            max-width: 200px;
            padding-bottom: 40px;
        }

        h1 {
            color: #EE7700;
            font-size: 32px;

            @media only screen and (max-width: 767.98px) {
                font-size: 24px;
            }
        }
    }

    form {
        display: block;
        margin: auto;


        .customCard {
            width: 80%;
            margin: 30px auto;
            padding: 50px 30px;
            background-color: #F9F9F9;

            @media only screen and (max-width: 1199.98px) {
                width: 90%;
            }
            @media only screen and (max-width: 991.98px) {
                width: 100%;
            }

            @media only screen and (max-width: 575.98px) {
                padding: 40px 20px;
            }

            @media only screen and (max-width: 349.98px) {
                padding: 40px 10px;
            }

            .formPart {
                padding: 0 60px;

                @media only screen and (max-width: 1199.98px) {
                    padding: 0 40px;
                }

                @media only screen and (max-width: 991.98px) {
                    padding: 0 20px;
                }

                @media only screen and (max-width: 575.98px) {
                    padding: 0;
                }
            }

            .form-check-input {
                width: 1em;
                min-width: 1em;
            }

            label {
                font-weight: 600;
                margin-bottom: 0;
            }

            .lighterLabel {
                font-weight: 400;
                font-size: 16px;
            }

        }
    }

    .buttonWrap {
        text-align: center;

        .btn {
            padding: 10px 60px;
            font-weight: 700;
        }
    }
}

</style>

<template>
    <section class="stepTwo">
        <div class="container">
            <div class="titleWrap">
                <img src="../assets/images/LOGO_NRG.png" alt="NRG awareness logo"/>
            </div>
            <div class="buttonWrap pt-5">
                <button class="btn btn-outline-secondary" @click="backToStepOne">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 122.88 122.88">
                        <defs></defs>
                        <title>back</title>
                        <path class="cls-1"
                              d="M61.44,0A61.51,61.51,0,1,1,18,18,61.25,61.25,0,0,1,61.44,0Zm5,45.27A7.23,7.23,0,1,0,56.14,35.13L35,56.57a7.24,7.24,0,0,0,0,10.15l20.71,21A7.23,7.23,0,1,0,66.06,77.62l-8.73-8.87,24.86-.15a7.24,7.24,0,1,0-.13-14.47l-24.44.14,8.84-9Z"/>
                    </svg>
                    Back
                </button>
            </div>
            <h2 class="text-center text-primary pb-5">Step 2</h2>
            <div class="accordion" id="roomAccordion">
                <room-details v-for="i in numOfRooms" :key="i" :pickedEmitter='pickedEmitter'
                              :numOfRooms='numOfRooms' :i="i" :roomNumber="roomNumber"
                              @nextRoom="nextRoom" @stepThree="stepThree" @previousRoom="previousRoom"
                @closeOtherRooms="closeOtherRooms" @deleteRoom="deleteRoom"></room-details>
            </div>
        </div>
    </section>
</template>

<script>
import roomDetails from '../components/roomDetails';
import store from "@/store";
import router from "@/router";

export default {
    data() {
        return {
            pickedEmitter: [],
            roomNumber: 1,
        }
    },
    computed: {
        numOfRooms() {
            return store.getters.getNumOfRooms;
        }
    },
    methods: {
        scrollToBegining() {
            window.scroll({top: 30, left: 0, behavior: 'smooth'});
        },
        previousRoom(){
            this.roomNumber -= 1;
            store.dispatch('saveRoomNum', this.roomNumber);
        },
        nextRoom(chosenRoom, roomLabel, selectedAge, airChange, selectedZone,
                 heatedSpaceBelow, heatedSpaceAbove, surfaceArea, height,
                 internalWalls, externalWalls, windows, doors,
                 lights, chimneys, openFlues, extractionFans) {
            this.saveData(chosenRoom, roomLabel, selectedAge, airChange, selectedZone,
                heatedSpaceBelow, heatedSpaceAbove, surfaceArea, height,
                internalWalls, externalWalls, windows, doors,
                lights, chimneys, openFlues, extractionFans);
            this.roomNumber += 1;
            store.dispatch('saveRoomNum', this.roomNumber);

            this.scrollToBegining();
        },
        stepThree(chosenRoom, roomLabel, selectedAge, airChange, selectedZone,
                  heatedSpaceBelow, heatedSpaceAbove, surfaceArea, height,
                  internalWalls, externalWalls, windows, doors,
                  lights, chimneys, openFlues, extractionFans) {
            this.saveData(chosenRoom, roomLabel, selectedAge, airChange, selectedZone,
                heatedSpaceBelow, heatedSpaceAbove, surfaceArea, height,
                internalWalls, externalWalls, windows, doors,
                lights, chimneys, openFlues, extractionFans);
            router.push({name: 'StepThree'});
        },
        saveData(chosenRoom, roomLabel, selectedAge, airChange, selectedZone,
                 heatedSpaceBelow, heatedSpaceAbove, surfaceArea, height,
                 internalWalls, externalWalls, windows, doors,
                 lights, chimneys, openFlues, extractionFans) {
            let roomData = {
                chosenRoom: chosenRoom,
                roomLabel: roomLabel,
                selectedAge: selectedAge,
                airChange: airChange,
                selectedZone: selectedZone,
                heatedSpaceBelow: heatedSpaceBelow,
                heatedSpaceAbove: heatedSpaceAbove,
                surfaceArea: surfaceArea,
                height: height,
                internalWalls: internalWalls,
                externalWalls: externalWalls,
                windows: windows,
                doors: doors,
                lights: lights,
                chimneys: chimneys,
                openFlues: openFlues,
                extractionFans: extractionFans
            }
            store.dispatch('saveRoomData',
                roomData
            );
        },
        backToStepOne() {
            router.push({name: 'StepOne'});
        },
        closeOtherRooms(i){
            this.roomNumber = i;
            store.dispatch('saveRoomNum', this.roomNumber);
        },
        deleteRoom(i){
            store.dispatch('deleteRoom', i);
            if(i === 1) {
                this.closeOtherRooms(i);
            } else {
                this.closeOtherRooms(i-1);
            }
            if(i-1 !== this.numOfRooms){
                window.location.reload();
            }
        },
    },
    mounted() {
        if (store.getters.getStepOneData) {
            let data = store.getters.getStepOneData;
            this.pickedEmitter = data.pickedEmitter;
        }
        if (store.getters.getRoomData.length < 1) {
            store.dispatch('saveRoomNum', 1);
            this.roomNumber = 1;
        } else if (store.getters.getRoomNum !== null) {
            this.roomNumber = store.getters.getRoomNum;
            if (this.roomNumber > store.getters.getRoomData.length){
                this.roomNumber -= 1;
            }
        }else{
            this.roomNumber = 1;
        }
        this.scrollToBegining();
    },
    components: {
        roomDetails
    }
}
</script>

<style lang="scss" scoped>
.stepTwo {
    padding: 30px 0;

    .titleWrap {
        img {
            max-width: 190px;
            height: auto;
        }
    }

    .accordion {
        padding-top: 20px;
    }

    .buttonWrap {
        .btn {
            padding: 10px 20px;
            font-weight: 700;

            svg{
                fill: #EE7700;
                width: 20px;
                height: auto;
            }
        }
    }
}
</style>

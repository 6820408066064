<template>
    <div class="accordion-item">
        <h2 class="accordion-header" :id='"heading"+i'>
            <button :class="['accordion-button', { collapsed: roomNumber !== i }]" type="button" disabled>
                <span v-if="roomLabel.length > 1">{{ roomLabel }}</span>
                <span v-else>Room {{ i }}</span>
            </button>
        </h2>
        <div :id='"collapse"+i' :class="['accordion-collapse', { collapse: roomNumber!==i }]"
             :aria-labelledby='"heading"+i'
             data-bs-parent="#roomAccordion">
            <div class="accordion-body" v-if="roomNumber===i">
                <div class="subSection">
                    <room-type @pickedRoom="pickedRoom" :i="i" @clearRoomData="clearRoomData" @deleteRoom="deleteRoom"></room-type>
                    <div class="row mt-2" v-if="typeError">
                        <div class="col-12">
                            <p class="text-danger p-2">{{ errorType }}</p>
                        </div>
                    </div>
                </div>

                <div class="subSection">
                    <div class="row pt-5">
                        <div class="col-lg-5 col-md-7">
                            <p><span class="numberForData">2</span> <label :for='"#roomLabel"+i' class="form-label">Assign
                                label
                                to your room
                                <span data-bs-toggle="tooltip" data-bs-placement="top"
                                      title="Assign label/name of your room. This value is required.">
                                    <svg-file></svg-file>
                                </span>
                            </label></p>
                        </div>
                        <div class="col-lg-7 col-md-5">
                            <input type="text" class="form-control" :id='"roomLabel"+i' v-model="roomLabel">
                        </div>
                    </div>
                    <div class="row mt-2" v-if="labelError">
                        <div class="col-12">
                            <p class="text-danger p-2">{{ error }}</p>
                        </div>
                    </div>
                </div>
                <div class="subSection">
                    <div class="row pt-5 pt-lg-3">
                        <div class="col-lg-5 col-md-7">
                            <p><span class="numberForData">3</span> <label :for='"#selectAge"+i' class="form-label">Building
                                age
                                <span data-bs-toggle="tooltip" data-bs-placement="top"
                                      title="When was the room built? This is used to estimate insulation quality (U-values and ventilation rates).">
                                    <svg-file></svg-file>
                                </span>
                            </label></p>
                        </div>
                        <div class="col-lg-7 col-md-5">
                            <select class="form-select" :id='"selectAge"+i' v-model="selectedAgeHelp">
                                <option selected disabled></option>
                                <option v-for="age in buildingAge" :key="age.id" :value="age.id">
                                    {{ age.age }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="ageError">
                        <div class="col-12">
                            <p class="text-danger p-2">{{ error }}</p>
                        </div>
                    </div>
                </div>
                <div class="subSection">
                    <div class="row py-3">
                        <div class="col-12 d-flex">
                            <h5>U-values
                                <span data-bs-toggle="tooltip" data-bs-placement="top"
                                      title="Default insulation values based on standard insulation practices at the time of construction. The values can be changed if you know your U-values, for example when you have done work to the insulation since the original construction. The estimated values are conservative so we reccomend that you replace them with accurate U-values for your build. 	 ">
                                    <svg-file></svg-file>
                            </span>
                            </h5>
                            <input class="form-check-input" type="checkbox" v-model="customUvalues" value="true"
                                   id="uValues">
                            <label class="form-check-label lighterLabel" for="uValues">
                                I know my U-values
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 disabledInput">
                            <span>Wall</span>
                            <input type="number" class="form-control" v-model.number="wallU" min="0" step="0.01"
                                   :disabled="!customUvalues" aria-label="U-value wall">
                        </div>
                        <div class="col-lg-2 col-md-6 disabledInput">
                            <span>Floor</span>
                            <input type="number" class="form-control" v-model.number="floorU" min="0" step="0.01"
                                   :disabled="!customUvalues" aria-label="U-value floor">
                        </div>
                        <div class="col-lg-2 col-md-6 disabledInput">
                            <span>Roof</span>
                            <input type="number" class="form-control" v-model.number="roofU" min="0" step="0.01"
                                   :disabled="!customUvalues" aria-label="U-value roof">
                        </div>
                        <div class="col-lg-2 col-md-6 disabledInput">
                            <span>Windows</span>
                            <input type="number" class="form-control" v-model.number="windowsU" min="0" step="0.01"
                                   :disabled="!customUvalues" aria-label="U-value windows">
                        </div>
                        <div class="col-lg-2 col-md-6 offset-md-3 offset-lg-0 disabledInput">
                            <span>Doors</span>
                            <input type="number" class="form-control" v-model.number="doorsU" min="0" step="0.01"
                                   :disabled="!customUvalues" aria-label="U-value doors">
                        </div>
                    </div>
                </div>
                <div class="subSection pt-5 pb-3">
                    <div class="row">
                        <div class="col-12 d-flex">
                            <h5>Room ventilation rate
                                <span data-bs-toggle="tooltip" data-bs-placement="top"
                                      title="Room ventilation rate depends on building age and room type, but you can override default value.">
                                    <svg-file></svg-file>
                            </span>
                            </h5>
                            <input class="form-check-input" type="checkbox" v-model="customAirChange" value="true"
                                   id="airChangeValue">
                            <label class="form-check-label lighterLabel" for="airChangeValue">
                                I know my ventilation rate
                            </label>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-12 d-flex align-items-center">
                            <span class="buildingAgePadding pe-4 pe-md-0">Ventilation rate</span>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 ms-md-5">
                                    <input type="number" class="form-control" v-model.number="airChange" min="0"
                                           step="0.01"
                                           :disabled="!customAirChange" aria-label="Air ventilation rate">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subSection">
                    <div class="row pt-5">
                        <div class="col-lg-4 col-md-6">
                            <p><span class="numberForData">4</span> <label class="form-label">Heating information
                            </label></p>
                        </div>
                    </div>
                    <div class="row paddingEl d-flex align-items-center">
                        <div
                            class="col-lg-4 col-md-6 pb-2 pb-md-0 d-flex justify-content-start">
                            <label :for='"#heatZone"+i' class="form-label lighterLabel">Which heating zone
                                is this room in?
                                <span data-bs-toggle="tooltip" data-bs-placement="top"
                                      title="Select the heating zone for the room. This value is required.">
                                    <svg-file></svg-file>
                                </span>
                            </label>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <select class="form-select" :id='"heatZone"+i' v-model="selectedZoneHelp">
                                <option selected disabled></option>
                                <option v-for="zone in pickedEmitter" :key="zone.id"
                                        :value="zone.id">
                                    {{ zone.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="zoneError">
                        <div class="col-12">
                            <p class="text-danger p-2">{{ error }}</p>
                        </div>
                    </div>
                    <div class="row paddingEl pt-4">
                        <div class="col-lg-6">
                            <div class="d-flex justify-content-start">
                                <label class="lighterLabel">Heated space below?
                                    <span data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Do you have an heated space below your room? This value is required.">
                                    <svg-file></svg-file>
                                </span>
                                </label>
                                <div class="form-check pe-4 ms-4">
                                    <input class="form-check-input" type="radio" :name='"heatedSpaceBelow"+i'
                                           :id='"heatedSpaceBelow1"+i' value="true" v-model="heatedSpaceBelow"
                                           :checked="heatedSpaceBelow === true">
                                    <label class="form-check-label lighterLabel" :for='"heatedSpaceBelow1"+i'>
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" :name='"heatedSpaceBelow"+i'
                                           :id='"heatedSpaceBelow2"+i' value="false" v-model="heatedSpaceBelow"
                                           :checked="heatedSpaceBelow === false">
                                    <label class="form-check-label lighterLabel" :for='"heatedSpaceBelow2"+i'>
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-2" v-if="belowError">
                                <div class="col-12">
                                    <p class="text-danger p-2">{{ error }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 pt-2 pt-lg-0">
                            <div class=" d-flex justify-content-start">
                                <label class="lighterLabel">Heated space above?
                                    <span data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Do you have an heated space above your room? This value is required.">
                                    <svg-file></svg-file>
                                </span>
                                </label>
                                <div class="form-check pe-4 ms-4">
                                    <input class="form-check-input" type="radio" :name='"heatedSpaceAbove"+i'
                                           :id='"heatedSpaceAbove1"+i' value="true" v-model="heatedSpaceAbove"
                                           :checked="heatedSpaceAbove === true">
                                    <label class="form-check-label lighterLabel" :for='"heatedSpaceAbove1"+i'>
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" :name='"heatedSpaceAbove"+i'
                                           :id='"heatedSpaceAbove2"+i' value="false" v-model="heatedSpaceAbove"
                                           :checked="heatedSpaceAbove === false">
                                    <label class="form-check-label lighterLabel" :for='"heatedSpaceAbove2"+i'>
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-2" v-if="aboveError">
                                <div class="col-12">
                                    <p class="text-danger p-2">{{ error }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subSection">
                    <div class="row pt-5">
                        <div class="col-lg-4 col-md-6">
                            <p><span class="numberForData">5</span> <label class="form-label">Room Dimensions
                                <span data-bs-toggle="tooltip" data-bs-placement="top"
                                      title="The accuracy of the dimensions determine the accuracy of the heat loss calculations.">
                                    <svg-file></svg-file>
                                </span></label>
                            </p>
                        </div>
                    </div>
                    <div class="row paddingEl d-flex align-items-center">
                        <div class="col-md-12">
                            <div class="roomDim">
                                <label :for='"#roomHeight"+i' class="form-label lighterLabel dimLabel">Average Ceiling
                                    Height [m]
                                    <span data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Enter the average height of the room. This value is required">
                                    <svg-file></svg-file>
                                </span>
                                </label>
                                <div>
                                    <input type="number" class="form-control smaller2" :id='"roomHeight"+i' min="0"
                                           step="0.01" placeholder="0.00"
                                           v-model.number="height">
                                </div>
                            </div>
                            <div class="row mt-2" v-if="heightError">
                                <div class="col-12">
                                    <p class="text-danger p-2">{{ error }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-4 pt-3">
                            <div class="col-md-6">
                                <div class="roomDim">
                                    <label :for='"#roomArea"+i' class="form-label lighterLabel dimLabel">Room Area
                                        [m<sup>2</sup>]
                                        <span data-bs-toggle="tooltip" data-bs-placement="top"
                                              title="Enter the room area if you know it. If unknown, click the button on the right to open a room area calculator that uses the room shape to calculate the surface area. Follow the calculator instructions carefully when entering data.">
                                    <svg-file></svg-file>
                                </span>
                                    </label>
                                    <div>
                                        <input type="number" class="form-control smaller2" :id='"roomArea"+i' min="0"
                                               step="0.01" placeholder="0.00"
                                               v-model.number="surfaceArea" :disabled="isSurfaceCalculated">
                                    </div>
                                </div>
                                <div class="row mt-2" v-if="surfaceError">
                                    <div class="col-12">
                                        <p class="text-danger p-2">{{ error }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pt-3 mt-1 roomDim">
                                <label class="form-label lighterLabel smallerLabel">Room area calculator
                                    <span data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Click here to open the room area calculator. Calculate the room area of the room by following the instructions on the screen.">
                                        <svg-file></svg-file>
                                    </span>
                                </label>
                                <!--<input class="form-check-input me-2" type="checkbox" id="unknownSurfaceArea"
                                       v-model="unknownSurfaceArea">-->
                                <button type="button" class="modalButton" data-bs-toggle="modal"
                                        :data-bs-target='"#calculateSurface"+i' @click="addClass">
                                </button>
                                <div class="modal fade surfaceModal" :id='"calculateSurface"+i'
                                     data-bs-backdrop="static"
                                     data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered  modal-lg modal-fullscreen-md-down">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="staticBackdropLabel">
                                                    {{ roomShape }} FOR SURFACE AREA CALCULATION
                                                </h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="roomShape">
                                                    <label class="lighterLabel">Room Shape
                                                        <span data-bs-toggle="tooltip" data-bs-placement="top"
                                                              title="Pick the room shape. The room can be shaped like rectangular, like L shape, and like T shape. This value is required.">
                                        <svg-file></svg-file>
                                    </span>
                                                    </label>
                                                    <div class="form-check">
                                                        <label class="form-check-label"
                                                               :for='"#roomShapeRectangular"+i'>
                                                            <input class="form-check-input" type="radio"
                                                                   name='"roomShape"+i'
                                                                   :id='"roomShapeRectangular"+i'
                                                                   value="Rectangular Shape"
                                                                   v-model="roomShape">
                                                            <img src="../assets/images/rectangular-room.png"
                                                                 alt="Rectangular shape"/>
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label" :for='"#roomShapeL"+i'>
                                                            <input class="form-check-input" type="radio"
                                                                   name='"roomShape"+i'
                                                                   :id='"roomShapeL"+i' value="L Shape"
                                                                   v-model="roomShape">
                                                            <img src="../assets/images/L-shaped-room.png"
                                                                 alt="L shape"/>
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label" :for='"#roomT"+i'>
                                                            <input class="form-check-input" type="radio"
                                                                   name='"roomShape"+i'
                                                                   :id='"roomShapeT"+i' value="T Shape"
                                                                   v-model="roomShape">
                                                            <img src="../assets/images/T.png" alt="T shape"/>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div v-if="roomShape === 'Rectangular Shape'">
                                                    <img src="../assets/images/Rectangular.png"/>
                                                    <p class="lighterLabel pt-3 pb-1">Please input walls of your room in
                                                        order like it shown on image. </p>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#a'+i" class="form-label lighterLabel">Wall A
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"a"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceA" placeholder="0.00">
                                                        <label :for="'#b'+i" class="form-label lighterLabel">Wall B
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"b"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceB" placeholder="0.00">
                                                    </div>
                                                </div>
                                                <div v-if="roomShape === 'L Shape'">
                                                    <img src="../assets/images/L_Shape.png"/>
                                                    <p class="lighterLabel pt-3 pb-1">Please input walls of your room in
                                                        order like it shown on image. </p>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#a'+i" class="form-label lighterLabel">Wall A
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"a"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceA" placeholder="0.00">
                                                        <label :for="'#b'+i" class="form-label lighterLabel">Wall B
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"b"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceB" placeholder="0.00">
                                                    </div>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#c'+i" class="form-label lighterLabel">Wall C
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"c"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceC" placeholder="0.00">
                                                        <label :for="'#d'+i" class="form-label lighterLabel">Wall D
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"d"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceD" placeholder="0.00">
                                                    </div>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#e'+i" class="form-label lighterLabel">Wall E
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"e"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceE" placeholder="0.00">
                                                        <label :for="'#f'+i" class="form-label lighterLabel me-1">Wall
                                                            F [m]</label>
                                                        <input type="number" class="form-control" :id='"f"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceF" placeholder="0.00">
                                                    </div>
                                                    <div v-if="errorCalculateSurface">
                                                        <p class="text-center">
                                                            Please check your input. Values should be in order like it's
                                                            shown on image. Rules: <br>
                                                            B &lt; D <br>
                                                            F &lt; D <br>
                                                            C &lt; E <br>
                                                            A &lt; E <br>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div v-if="roomShape === 'T Shape'">
                                                    <img src="../assets/images/T_Shape.png"/>
                                                    <p class="lighterLabel pt-3 pb-1">Please input walls of your room in
                                                        order like it shown on image. </p>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#a'+i" class="form-label lighterLabel">Wall A
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"a"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceA" placeholder="0.00">
                                                        <label :for="'#b'+i" class="form-label lighterLabel">Wall B
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"b"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceB" placeholder="0.00">
                                                    </div>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#c'+i" class="form-label lighterLabel">Wall C
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"c"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceC" placeholder="0.00">
                                                        <label :for="'#d'+i" class="form-label lighterLabel">Wall D
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"d"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceD" placeholder="0.00">
                                                    </div>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#e'+i" class="form-label lighterLabel">Wall E
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"e"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceE" placeholder="0.00">
                                                        <label :for="'#f'+i" class="form-label lighterLabel me-1">Wall
                                                            F [m]</label>
                                                        <input type="number" class="form-control" :id='"f"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceF" placeholder="0.00">
                                                    </div>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#g'+i" class="form-label lighterLabel">Wall G
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"g"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceG" placeholder="0.00">
                                                        <label :for="'#h'+i" class="form-label lighterLabel">Wall H
                                                            [m]</label>
                                                        <input type="number" class="form-control" :id='"h"+i' min="0.01"
                                                               step="0.01"
                                                               v-model.number="surfaceH" placeholder="0.00">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                                    Close
                                                </button>
                                                <button type="button" class="btn btn-primary" @click="calculateSurface">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row paddingEl">
                        <div class="col-lg-6">
                            <div class="row pt-3 pt-lg-0">
                                <div class="col-lg-8 col-9">
                                    <label for="numOfInternalWalls" class="form-label lighterLabel">Internal walls: How
                                        many internal
                                        walls does your room
                                        have?
                                        <span data-bs-toggle="tooltip" data-bs-placement="top"
                                              title="Enter the number of internal walls. Then enter the length of each internal walls in the fields that appear. The wall length must be greater than 0">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-lg-4 col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="numOfInternalWalls" min="1" max="100" step="1"
                                           @input="num = $event.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, '');$event.target.value = num"
                                           v-model.number="numOfInternalWalls" required>
                                </div>
                            </div>
                            <div class="row mt-2" v-if="internalWallsError">
                                <div class="col-12">
                                    <p class="text-danger p-2">{{ error }}</p>
                                </div>
                            </div>
                            <div class="row pt-4" v-for="index in numOfInternalWalls" :key="index">
                                <div class="col-lg-12">
                                    <div class="row d-flex align-items-center">
                                        <div class="col-md-6">
                                            <label :for="'#internalWall'+index" class="form-label lighterLabel">Length
                                                of wall {{ index }} [m]
                                            </label>
                                        </div>
                                        <div class="col-md-6 lineInput">
                                            <input type="number" class="form-control smaller" :id="'internalWall'+index"
                                                   required min="0" step="0.01" placeholder="0.00"
                                                   v-model.number="internalWalls[index-1]">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2" v-if="internalError[index-1]">
                                    <div class="col-12">
                                        <p class="text-danger p-2">{{ error }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row pt-3 pt-lg-0">
                                <div class="col-lg-8 col-9">
                                    <label for="numOfExternalWalls" class="form-label lighterLabel">External walls: How
                                        many external
                                        walls does your room
                                        have?
                                        <span data-bs-toggle="tooltip" data-bs-placement="top"
                                              title="Enter the number of external walls. Then enter the length of each external walls in the fields that appear. The wall length must be greater than 0">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-lg-4 col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="numOfExternalWalls" min="1" max="100" step="1"
                                           @input="num = $event.target.value.replace(/[,\s]+/g, '').replace(/[^\d]/g,'');$event.target.value = num"
                                           v-model.number="numOfExternalWalls" required>
                                </div>
                                <div class="row pt-4" v-for="index in numOfExternalWalls" :key="index">
                                    <div class="col-lg-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-6">
                                                <label :for="'#externalWall'+index" class="form-label lighterLabel">Length
                                                    of wall {{ index }} [m]
                                                </label>
                                            </div>
                                            <div class="col-md-6 lineInput">
                                                <input type="number" class="form-control smaller"
                                                       :id="'externalWall'+index" min="0" step="0.01" placeholder="0.00"
                                                       required
                                                       v-model.number="externalWalls[index-1]">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subSection">
                    <div class="row pt-5">
                        <div class="col-lg-4 col-md-12">
                            <p><span class="numberForData">6</span> <label :for='"#doorsWindows"+i' class="form-label">Doors
                                and
                                Windows</label></p>
                        </div>
                    </div>
                    <div class="row paddingEl">
                        <div class="col-lg-6">
                            <div class="row d-flex align-items-center">
                                <div class="col-9">
                                    <label for="numOfWindows" class="form-label lighterLabel">How many windows does your
                                        room
                                        have?
                                        <span data-bs-toggle="tooltip" data-bs-placement="top"
                                              title="Enter the number of windows. Then enter the surface area of each window in the fields that appear. The area must be greater than 0.">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="numOfWindows" min="0" max="100" step="1"
                                           @input="num = $event.target.value.replace(/[,\s]+/g, '').replace(/[^\d]/g,'');$event.target.value = num"
                                           v-model.number="numOfWindows" required>
                                </div>
                                <div class="row pt-4" v-for="index in numOfWindows" :key="index">
                                    <div class="col-lg-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-6">
                                                <label :for="'#windows'+i+index" class="form-label lighterLabel">Surface
                                                    area of window {{ index }} [m<sup>2</sup>]
                                                </label>
                                            </div>
                                            <div class="col-md-6 lineInput">
                                                <input type="number" class="form-control smaller"
                                                       :id="'windows'+i+index"
                                                       required min="0" step="0.01" placeholder="0.00"
                                                       v-model.number="windows[index-1]">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="d-flex align-items-center">
                                            <label class="form-label lighterLabel smallerLabel pe-3">Window area
                                                calculator</label>
                                            <button type="button" class="modalButton" data-bs-toggle="modal"
                                                    :data-bs-target='"#windowsModal"+i+index'>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="modal fade" :id='"windowsModal"+i+index' data-bs-backdrop="static"
                                         data-bs-keyboard="false" tabindex="-1"
                                         :aria-labelledby='"windowsModalLabel"+i+index'
                                         aria-hidden="true">
                                        <div
                                            class="modal-dialog modal-dialog-centered  modal-lg modal-fullscreen-md-down">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" :id='"windowsModalLabel"+i'>
                                                        Calculate surface area of window {{ index }}
                                                    </h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <img src="../assets/images/Rectangular.png"/>
                                                    <p class="lighterLabel pt-3 pb-1">Please input window
                                                        dimensions. </p>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#modalWidnowA'+i" class="form-label lighterLabel">Window
                                                            dimension A [m]</label>
                                                        <input type="number" class="form-control" :id='"modalWidnowA"+i'
                                                               min="0.01" step="0.01"
                                                               v-model.number="windowSurfaceA[index-1]"
                                                               placeholder="0.00">
                                                        <label :for="'#modalWidnowB'+i" class="form-label lighterLabel">Window
                                                            dimension B [m]</label>
                                                        <input type="number" class="form-control" :id='"modalWidnowB"+i'
                                                               min="0.01" step="0.01"
                                                               v-model.number="windowSurfaceB[index-1]"
                                                               placeholder="0.00">
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">
                                                        Close
                                                    </button>
                                                    <button type="button" class="btn btn-primary"
                                                            data-bs-dismiss="modal"
                                                            @click="calculateWindowSurface(index)">Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 pt-3 pt-lg-0">
                            <div class="row d-flex align-items-center">
                                <div class="col-9">
                                    <label for="numOfDoors" class="form-label lighterLabel">How many external
                                        doors does your room
                                        have?
                                        <span data-bs-toggle="tooltip" data-bs-placement="top"
                                              title="Enter the number of external doors. Then enter the surface area of each external door in the fields that appear. The area must be greater than 0.">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="numOfDoors" min="0" max="100" step="1"
                                           @input="num = $event.target.value.replace(/[,\s]+/g, '').replace(/[^\d]/g,'');$event.target.value = num"
                                           v-model.number="numOfDoors" required>
                                </div>
                                <div class="row pt-4" v-for="index in numOfDoors" :key="index">
                                    <div class="col-lg-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-6">
                                                <label :for="'#doors'+i+index" class="form-label lighterLabel">Surface
                                                    area of door {{ index }} [m<sup>2</sup>]
                                                </label>
                                            </div>
                                            <div class="col-md-6 lineInput">
                                                <input type="number" class="form-control smaller" :id="'doors'+i+index"
                                                       required min="0" step="0.01" placeholder="0.00"
                                                       v-model.number="doors[index-1]">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="d-flex align-items-center">
                                            <label class="form-label lighterLabel smallerLabel pe-3">Door area
                                                calculator</label>
                                            <button type="button" class="modalButton" data-bs-toggle="modal"
                                                    :data-bs-target='"#doorsModal"+i+index'>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="modal fade" :id='"doorsModal"+i+index' data-bs-backdrop="static"
                                         data-bs-keyboard="false" tabindex="-1"
                                         :aria-labelledby='"doorsModalLabel"+i+index'
                                         aria-hidden="true">
                                        <div
                                            class="modal-dialog modal-dialog-centered  modal-lg modal-fullscreen-md-down">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" :id='"windowsModalLabel"+i'>
                                                        Calculate surface area of door {{ index }}
                                                    </h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <img src="../assets/images/Rectangular.png"/>
                                                    <p class="lighterLabel pt-3 pb-1">Please input door
                                                        dimensions. </p>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#modalDoorA'+i" class="form-label lighterLabel">Door
                                                            dimension A [m]</label>
                                                        <input type="number" class="form-control" :id='"modalDoorA"+i'
                                                               min="0.01" step="0.01"
                                                               v-model.number="doorSurfaceA[index-1]"
                                                               placeholder="0.00">
                                                        <label :for="'#modalDoorB'+i" class="form-label lighterLabel">Door
                                                            dimension B [m]</label>
                                                        <input type="number" class="form-control" :id='"modalDoorB"+i'
                                                               min="0.01" step="0.01"
                                                               v-model.number="doorSurfaceB[index-1]"
                                                               placeholder="0.00">
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">
                                                        Close
                                                    </button>
                                                    <button type="button" class="btn btn-primary"
                                                            data-bs-dismiss="modal"
                                                            @click="calculateDoorSurface(index)">Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 pt-3">
                            <div class="row d-flex align-items-center">
                                <div class="col-9">
                                    <label for="roofLights" class="form-label lighterLabel">How many roof lights does
                                        your room
                                        have?
                                        <span data-bs-toggle="tooltip" data-bs-placement="top"
                                              title="Roof lights are sometimes referred to as 'skylights' and are windows built into the roof of the building. Enter the number of roof lights. Then enter the surface area of each roof light in the fields that appear. The area must be greater than 0">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="roofLights" min="0" max="100" step="1"
                                           @input="num = $event.target.value.replace(/[,\s]+/g, '').replace(/[^\d]/g,'');$event.target.value = num"
                                           v-model.number="roofLights" required>
                                </div>
                                <div class="row pt-4" v-for="index in roofLights" :key="index">
                                    <div class="col-lg-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-6">
                                                <label :for="'#lights'+i+index" class="form-label lighterLabel">Surface
                                                    area of roof light {{ index }} [m<sup>2</sup>]
                                                </label>
                                            </div>
                                            <div class="col-md-6 lineInput">
                                                <input type="number" class="form-control smaller" :id="'lights'+i+index"
                                                       required min="0" step="0.01" placeholder="0.00"
                                                       v-model.number="lights[index-1]">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="d-flex align-items-center">
                                            <label class="form-label lighterLabel smallerLabel pe-3">Roof light area
                                                calculator</label>
                                            <button type="button" class="modalButton" data-bs-toggle="modal"
                                                    :data-bs-target='"#roofLightsModal"+i+index'>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="modal fade" :id='"roofLightsModal"+i+index' data-bs-backdrop="static"
                                         data-bs-keyboard="false" tabindex="-1"
                                         :aria-labelledby='"roofLightsModalLabel"+i+index'
                                         aria-hidden="true">
                                        <div
                                            class="modal-dialog modal-dialog-centered  modal-lg modal-fullscreen-md-down">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" :id='"roofLightsModalLabel"+i'>
                                                        Calculate surface area of roof light {{ index }}
                                                    </h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <img src="../assets/images/Rectangular.png"/>
                                                    <p class="lighterLabel pt-3 pb-1">Please input roof light
                                                        dimensions. </p>
                                                    <div class="input-group pb-3">
                                                        <label :for="'#modalRoofLightsA'+i"
                                                               class="form-label lighterLabel">Roof light
                                                            dimension A [m]</label>
                                                        <input type="number" class="form-control"
                                                               :id='"modalRoofLightsA"+i'
                                                               min="0.01" step="0.01"
                                                               v-model.number="roofLightSurfaceA[index-1]"
                                                               placeholder="0.00">
                                                        <label :for="'#modalRoofLightsB'+i"
                                                               class="form-label lighterLabel">Roof light
                                                            dimension B [m]</label>
                                                        <input type="number" class="form-control"
                                                               :id='"modalRoofLightsB"+i'
                                                               min="0.01" step="0.01"
                                                               v-model.number="roofLightSurfaceB[index-1]"
                                                               placeholder="0.00">
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">
                                                        Close
                                                    </button>
                                                    <button type="button" class="btn btn-primary"
                                                            data-bs-dismiss="modal"
                                                            @click="calculateRoofLightSurface(index)">Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subSection">
                    <div class="row pt-5">
                        <div class="col-lg-4 col-md-12">
                            <p><span class="numberForData">7</span> <label :for='"#addInfo"+i' class="form-label">Ventilation
                                information</label></p>
                        </div>
                    </div>
                    <div class="row paddingEl">
                        <div class="col-xl-4 col-lg-6">
                            <div class="row d-flex align-items-center">
                                <div class="col-9">
                                    <label for="chimneys" class="form-label lighterLabel">Number of open chimneys
                                        <span data-bs-toggle="tooltip" data-bs-placement="top" title="The number of open chimneys in the room.
                                        This value can be 0.">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="chimneys" min="0" max="3" step="1"
                                           @input="num = $event.target.value.replace(/[,\s]+/g, '').replace(/[^\d]/g,'');$event.target.value = num"
                                           v-model.number="chimneys" required>
                                </div>
                            </div>
                            <div class="row mt-2" v-if="chimneyError">
                                <div class="col-12">
                                    <p class="text-danger p-2">This value needs to be between 0 and 3</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 pt-3 pt-lg-0">
                            <div class="row d-flex align-items-center">
                                <div class="col-9">
                                    <label for="openFlues" class="form-label lighterLabel">Number of open appliance
                                        flues
                                        <span data-bs-toggle="tooltip" data-bs-placement="top" title="The number of open appliance flues in the room.
                                        This value can be 0.">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="openFlues" min="0" max="3" step="1"
                                           @input="num = $event.target.value.replace(/[,\s]+/g, '').replace(/[^\d]/g,'');$event.target.value = num"
                                           v-model.number="openFlues" required>
                                </div>
                            </div>
                            <div class="row mt-2" v-if="fluesError">
                                <div class="col-12">
                                    <p class="text-danger p-2">This value needs to be between 0 and 3</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 pt-3 pt-xl-0">
                            <div class="row d-flex align-items-center">
                                <div class="col-9">
                                    <label for="extractionFans" class="form-label lighterLabel">Number of Extraction
                                        Fans
                                        <span data-bs-toggle="tooltip" data-bs-placement="top" title="The number of extraction fans in the room.
                                        This value can be 0.">
                                        <svg-file></svg-file>
                                    </span>
                                    </label>
                                </div>
                                <div class="col-3 lineInput">
                                    <input type="number" class="form-control smaller"
                                           id="extractionFans" min="0" max="3" step="1"
                                           @input="num = $event.target.value.replace(/[,\s]+/g, '').replace(/[^\d]/g,'');$event.target.value = num"
                                           v-model.number="extractionFans" required>
                                </div>
                            </div>
                            <div class="row mt-2" v-if="fansError">
                                <div class="col-12">
                                    <p class="text-danger p-2">This value needs to be between 0 and 3</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttonWrap py-4 mt-4">
                    <button class="btn btn-outline-secondary mb-4 mb-md-0 me-md-5" @click="previousRoom"
                            :disabled="i===1">Previous room
                    </button>
                    <button class="btn btn-outline-primary" @click="nextRoom(true)" v-if="roomNumber === numOfRooms" :disabled="completeError">Add room</button>
                    <button class="btn btn-outline-primary" @click="nextRoom(false)" v-else>Next Room</button>
                    <div class="row mt-2" v-if="completeError">
                        <div class="col-12">
                            <p class="text-danger p-2">You need to input data for all rooms</p>
                        </div>
                    </div>
                    <div class="pt-5 mt-3" v-if="roomNumber === numOfRooms">
                        <hr>
                        <p class="pt-4">Are you done? <br> Finish your calculations now!</p>
                        <button class="btn btn-primary" type="submit" @click.prevent="finish"
                                v-if="roomNumber === numOfRooms">Complete
                            <span data-bs-toggle="tooltip" data-bs-placement="top"
                                  title="You need to input data for all rooms">
                            <svg-file></svg-file>
                        </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import buildingAge from '../jsonFiles/buildingAge.json';
import airVentilation from '../jsonFiles/airVentilation.json';
import roomType from "./roomType";
import * as bootstrap from "bootstrap";
import svgFile from './svgFile';
import store from "@/store";

export default {
    props: {
        pickedEmitter: {
            type: Array,
            required: true,
        },
        numOfRooms: {
            type: Number,
            required: true,
        },
        i: {
            type: Number,
            required: true,
        },
        roomNumber: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            buildingAge: buildingAge,
            airVentilation: airVentilation,

            chosenRoom: null,
            roomLabel: "",
            selectedAge: {
                id: null,
                age: '',
                roof: null,
                wall: null,
                floor: null,
                windows: null,
                doors: null,
                customU: false,
            },
            selectedAgeHelp: null,
            airChange: null,
            customAirChange: false,
            selectedZone: {
                name: "",
                heatEmitter: {
                    id: null,
                    name: "",
                    customValues: null,
                    flow: null,
                    return: null,
                }
            },
            selectedZoneHelp: null,
            heatedSpaceBelow: null,
            heatedSpaceAbove: null,
            surfaceArea: null,
            height: null,
            roomShape: '',
            unknownSurfaceArea: null,
            surfaceA: null,
            surfaceB: null,
            surfaceC: null,
            surfaceD: null,
            surfaceE: null,
            surfaceF: null,
            surfaceG: null,
            surfaceH: null,
            errorCalculateSurface: false,
            windowSurfaceA: [],
            windowSurfaceB: [],
            doorSurfaceA: [],
            doorSurfaceB: [],
            roofLightSurfaceA: [],
            roofLightSurfaceB: [],

            numOfInternalWalls: null,
            internalWalls: [],
            numOfExternalWalls: null,
            externalWalls: [],
            numOfWindows: 0,
            windows: [],
            numOfDoors: 0,
            doors: [],
            roofLights: 0,
            lights: [],
            chimneys: 0,
            openFlues: 0,
            extractionFans: 0,

            error: "This field is required",
            errorType: "Please select a room type from list above",
            typeError: false,
            labelError: false,
            ageError: false,
            zoneError: false,
            belowError: false,
            aboveError: false,
            surfaceError: false,
            heightError: false,
            shapeError: false,
            internalWallsError: false,
            internalError: [],
            chimneyError: false,
            fluesError: false,
            fansError: false,
            completeError: false,

            beforeValue1: 0,
            beforeValue2: 0,
            beforeValue3: 0,
            beforeValue4: 0,
            beforeValue5: 0,

            isSurfaceCalculated: false,
            customUvalues: false,
            floorU: null,
            roofU: null,
            wallU: null,
            windowsU: null,
            doorsU: null,
        }
    },
    methods: {
        pickedRoom(chosenRoom) {
            this.chosenRoom = chosenRoom;
        },
        addClass() {
            document.querySelector('.modalButton').classList.add('clicked');
        },
        calculateSurface() {
            if (this.roomShape === 'Rectangular Shape') {
                this.surfaceArea = this.surfaceA * this.surfaceB;
                if (this.surfaceArea > 0) {
                    this.isSurfaceCalculated = true;
                    const truck_modal = document.querySelector('#calculateSurface' + this.i);
                    const modal = bootstrap.Modal.getInstance(truck_modal);
                    modal.hide();
                }
            } else if (this.roomShape === 'L Shape') {
                if (this.surfaceB < this.surfaceD && this.surfaceF < this.surfaceD && this.surfaceC < this.surfaceE && this.surfaceA < this.surfaceE) {
                    this.surfaceArea = ((this.surfaceA + this.surfaceC) * this.surfaceD) - (this.surfaceA * this.surfaceB);
                    this.errorCalculateSurface = false;
                    if (this.surfaceArea > 0) {
                        this.isSurfaceCalculated = true;
                        const truck_modal = document.querySelector('#calculateSurface' + this.i);
                        const modal = bootstrap.Modal.getInstance(truck_modal);
                        modal.hide();
                    }
                } else {
                    this.errorCalculateSurface = true;
                    this.isSurfaceCalculated = false;
                }
            } else if (this.roomShape === 'T Shape') {
                this.surfaceArea = ((this.surfaceA + this.surfaceC) * this.surfaceD) - ((this.surfaceA * this.surfaceB) + (this.surfaceG * this.surfaceF));
                if (this.surfaceArea > 0) {
                    this.isSurfaceCalculated = true;
                    const truck_modal = document.querySelector('#calculateSurface' + this.i);
                    const modal = bootstrap.Modal.getInstance(truck_modal);
                    modal.hide();
                }
            }
        },
        calculateWindowSurface(index) {
            this.windows[index - 1] = this.windowSurfaceA[index - 1] * this.windowSurfaceB[index - 1];
        },
        calculateDoorSurface(index) {
            this.doors[index - 1] = this.doorSurfaceA[index - 1] * this.doorSurfaceB[index - 1];
        },
        calculateRoofLightSurface(index) {
            this.lights[index - 1] = this.roofLightSurfaceA[index - 1] * this.roofLightSurfaceB[index - 1];
        },
        previousRoom() {
            this.$emit("previousRoom");
        },
        nextRoom(addNew) {
            this.checkForUvalues();
            const validationInternalWalls = this.internalWalls.includes(undefined);
            if (this.chosenRoom !== null && this.chosenRoom.id !== null && this.roomLabel.length >= 1 && this.selectedAge.id !== null && this.airChange !== null && this.selectedZone.heatEmitter.id !== null &&
                this.heatedSpaceBelow !== null && this.heatedSpaceAbove !== null && this.surfaceArea !== null && this.height !== null
                && this.numOfInternalWalls > 0 && !validationInternalWalls && this.chimneys <= 3 && this.openFlues <= 3 && this.extractionFans <= 3) {

                this.typeError = false;
                this.labelError = false;
                this.ageError = false;
                this.zoneError = false;
                this.belowError = false;
                this.aboveError = false;
                this.surfaceError = false;
                this.heightError = false;
                this.shapeError = false;
                this.internalWallsError = false;
                this.internalError = [];
                this.chimneyError = false;
                this.fluesError = false;
                this.fansError = false;
                this.completeError = false;
                if(addNew){
                    store.dispatch('addRoom');
                }
                this.$emit("nextRoom", this.chosenRoom, this.roomLabel, this.selectedAge, this.airChange, this.selectedZone,
                    this.heatedSpaceBelow, this.heatedSpaceAbove, this.surfaceArea, this.height,
                    this.internalWalls, this.externalWalls, this.windows, this.doors,
                    this.lights, this.chimneys, this.openFlues, this.extractionFans);
            } else {
                this.checkErrors(validationInternalWalls);
            }
        },
        finish() {
            this.checkForUvalues();
            const validationInternalWalls = this.internalWalls.includes(undefined);
            if (this.chosenRoom !== null && this.roomLabel.length >= 1 && this.selectedAge.id !== null && this.airChange !== null && this.selectedZone.id !== null &&
                this.heatedSpaceBelow !== null && this.heatedSpaceAbove !== null && this.surfaceArea !== null && this.height !== null &&
                this.numOfInternalWalls > 0 && !validationInternalWalls && this.chimneys <= 3 && this.openFlues <= 3 && this.extractionFans <= 3) {
                if (store.getters.getRoomData.length >= this.numOfRooms - 1) {
                    this.completeError = false;
                    this.$emit("stepThree", this.chosenRoom, this.roomLabel, this.selectedAge, this.airChange, this.selectedZone,
                        this.heatedSpaceBelow, this.heatedSpaceAbove, this.surfaceArea, this.height,
                        this.internalWalls, this.externalWalls, this.windows, this.doors,
                        this.lights, this.chimneys, this.openFlues, this.extractionFans);
                } else {
                    this.completeError = true;
                }
            } else {
                this.checkErrors(validationInternalWalls);
            }
        },
        checkErrors(validationInternalWalls) {
            if (this.chosenRoom === null) {
                this.typeError = true;
            } else {
                if (this.chosenRoom.id === null) {
                    this.typeError = true;
                } else {
                    this.typeError = false;
                }
            }

            if (this.roomLabel < 1) {
                this.labelError = true;
            } else {
                this.labelError = false;
            }

            if (!this.selectedAge.id) {
                this.ageError = true;
            } else {
                this.ageError = false;
            }

            if (this.selectedZone.heatEmitter.id === null) {
                this.zoneError = true;
            } else {
                this.zoneError = false;
            }
            if (this.heatedSpaceBelow === null) {
                this.belowError = true;
            } else {
                this.belowError = false;
            }
            if (this.heatedSpaceAbove === null) {
                this.aboveError = true;
            } else {
                this.aboveError = false;
            }
            if (this.surfaceArea === null || this.surfaceArea < 1) {
                this.surfaceError = true;
            } else {
                this.surfaceError = false;
            }
            if (this.height === null || this.height < 0.5) {
                this.heightError = true;
            } else {
                this.heightError = false;
            }
            if (this.numOfInternalWalls < 1) {
                this.internalWallsError = true;
            } else {
                this.internalWallsError = false;
            }
            this.internalError = [];

            if (validationInternalWalls === true) {
                for (let i = 0; i < this.numOfInternalWalls; i++) {
                    if (this.internalWalls[i] === undefined) {
                        this.internalError.push(true);
                    } else {
                        this.internalError.push(false);
                    }
                }
            } else if (validationInternalWalls === false && this.numOfInternalWalls) {
                for (let i = 0; i < this.numOfInternalWalls.length; i++) {
                    this.internalError.push(false);
                }
            }

            if (this.chimneys === null || this.chimneys > 3) {
                this.chimneyError = true;
            } else {
                this.chimneyError = false;
            }
            if (this.openFlues === null || this.openFlues > 3) {
                this.fluesError = true;
            } else {
                this.fluesError = false;
            }
            if (this.extractionFans === null || this.extractionFans > 3) {
                this.fansError = true;
            } else {
                this.fansError = false;
            }

            this.$nextTick(() => {
                this.scrollToFirstError()
            })

        },

        scrollToFirstError() {
            const firstError = document.querySelector('.text-danger');
            if (firstError) {
                firstError.scrollIntoView({ behavior: 'smooth' });
            }
        },

        checkForUvalues() {
            if(this.selectedAgeHelp !== null){
                let ageFind = this.buildingAge;
                let temp = ageFind.find(x => x.id === this.selectedAgeHelp);
                this.selectedAge.id = temp.id;
                this.selectedAge.customU = this.customUvalues;

                if (!this.customUvalues) {
                    if (this.selectedAgeHelp !== null) {
                        this.selectedAge.roof = temp.roof;
                        this.selectedAge.wall = temp.wall;
                        this.selectedAge.windows = temp.windows;
                        this.selectedAge.doors = temp.doors;
                        this.selectedAge.floor = temp.floor;
                        this.selectedAge.age = temp.age;

                        this.roofU = this.selectedAge.roof;
                        this.wallU = this.selectedAge.wall;
                        this.windowsU = this.selectedAge.windows;
                        this.doorsU = this.selectedAge.doors;
                        this.floorU = this.selectedAge.floor;

                    } /*else {
                        this.selectedAge = this.buildingAge.find(x => x.id === 9);
                    }*/

                    if (this.selectedZone.heatEmitter.id === 1) {
                        this.selectedAge.floor = 0.15;
                        this.floorU = 0.15;
                    }
                } else {
                    if (this.selectedAge.id) {
                        this.selectedAge.floor = this.floorU;
                        this.selectedAge.roof = this.roofU;
                        this.selectedAge.wall = this.wallU;
                        this.selectedAge.windows = this.windowsU;
                        this.selectedAge.doors = this.doorsU;
                    }
                }
            }
        },
        checkVentilationRate() {
            if (this.selectedAge.id !== null && this.chosenRoom !== null && this.customAirChange === false) {
                for (let i = 0; i < this.airVentilation.length; i++) {
                    if (this.chosenRoom.id === this.airVentilation[i].id) {
                        if (this.selectedAge.id <= 6) {
                            this.airChange = this.airVentilation[i]["<2005"];
                            return;
                        } else if (this.selectedAge.id > 6 && this.selectedAge.id < 9) {
                            this.airChange = this.airVentilation[i]["2005-2010"];
                            return;
                        } else {
                            this.airChange = this.airVentilation[i][">2011"];
                            return;
                        }
                    }
                }
            }
        },
        changeRoomNum() {
            this.$emit('closeOtherRooms', this.i);
        },
        clearRoomData() {
            this.chosenRoom = null;
            this.roomLabel = "";
            this.selectedAgeHelp = null;
            this.selectedAge = {
                id: null,
                age: '',
                roof: null,
                wall: null,
                floor: null,
                windows: null,
                doors: null
            };
            this.selectedZoneHelp = null;
            this.customAirChange = false;
            this.airChange = null;
            this.selectedZone = {
                name: "",
                heatEmitter: {
                    id: null,
                    name: "",
                    customValues: null,
                    flow: null,
                    return: null,
                }
            };
            this.heatedSpaceBelow = null;
            this.heatedSpaceAbove = null;
            this.surfaceArea = null;
            this.height = null;
            this.roomShape = '';
            this.unknownSurfaceArea = null;
            this.surfaceA = null;
            this.surfaceB = null;
            this.surfaceC = null;
            this.surfaceD = null;
            this.surfaceE = null;
            this.surfaceF = null;
            this.surfaceG = null;
            this.surfaceH = null;
            this.errorCalculateSurface = false;
            this.windowSurfaceA = [];
            this.windowSurfaceB = [];
            this.doorSurfaceA = [];
            this.doorSurfaceB = [];
            this.roofLightSurfaceA = [];
            this.roofLightSurfaceB = [];

            this.numOfInternalWalls = null;
            this.internalWalls = [];
            this.numOfExternalWalls = null;
            this.externalWalls = [];
            this.numOfWindows = 0;
            this.windows = [];
            this.numOfDoors = 0;
            this.doors = [];
            this.roofLights = 0;
            this.lights = [];
            this.chimneys = 0;
            this.openFlues = 0;
            this.extractionFans = 0;

            this.typeError = false;
            this.labelError = false;
            this.ageError = false;
            this.zoneError = false;
            this.belowError = false;
            this.aboveError = false;
            this.surfaceError = false;
            this.heightError = false;
            this.shapeError = false;
            this.internalWallsError = false;
            this.internalError = [];
            this.chimneyError = false;
            this.fluesError = false;
            this.fansError = false;
            this.completeError = false;

            this.beforeValue1 = 0;
            this.beforeValue2 = 0;
            this.beforeValue3 = 0;
            this.beforeValue4 = 0;
            this.beforeValue5 = 0;

            this.isSurfaceCalculated = false;
            this.customUvalues = false;
            this.floorU = null;
            this.roofU = null;
            this.wallU = null;
            this.windowsU = null;
            this.doorsU = null;
            store.dispatch('clearData');
        },
        deleteRoom(){
            this.$emit('deleteRoom', this.i);
        },
        checkStore(){
            if (store.getters.getRoomData.length >= this.i) {
                let data = store.getters.getRoomData;
                this.chosenRoom = data[this.i - 1].chosenRoom;
                this.roomLabel = data[this.i - 1].roomLabel;
                this.selectedAge = data[this.i - 1].selectedAge;
                this.customUvalues = this.selectedAge.customU;
                this.floorU = this.selectedAge.floor;
                this.roofU = this.selectedAge.roof;
                this.wallU = this.selectedAge.wall;
                this.windowsU = this.selectedAge.windows;
                this.doorsU = this.selectedAge.doors;

                this.airChange = data[this.i - 1].airChange;

                this.selectedZone = data[this.i - 1].selectedZone;
                this.selectedZoneHelp = data[this.i - 1].selectedZone.id;

                this.heatedSpaceBelow = data[this.i - 1].heatedSpaceBelow;
                this.heatedSpaceAbove = data[this.i - 1].heatedSpaceAbove;

                this.surfaceArea = data[this.i - 1].surfaceArea;
                this.height = data[this.i - 1].height;
                this.roomShape = data[this.i - 1].roomShape;
                this.unknownSurfaceArea = data[this.i - 1].unknownSurfaceArea;

                this.numOfInternalWalls = data[this.i - 1].internalWalls.length;
                this.internalWalls = data[this.i - 1].internalWalls;
                this.numOfExternalWalls = data[this.i - 1].externalWalls.length;
                this.externalWalls = data[this.i - 1].externalWalls;

                this.numOfWindows = data[this.i - 1].windows.length;
                this.windows = data[this.i - 1].windows;

                this.numOfDoors = data[this.i - 1].doors.length;
                this.doors = data[this.i - 1].doors;

                this.roofLights = data[this.i - 1].lights.length;
                this.lights = data[this.i - 1].lights;
                this.chimneys = data[this.i - 1].chimneys;
                this.openFlues = data[this.i - 1].openFlues;
                this.extractionFans = data[this.i - 1].extractionFans;

                this.selectedAgeHelp = data[this.i - 1].selectedAge.id;

            }
        }
    },
    mounted() {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
        this.checkStore();
    },
    watch: {
        chosenRoom() {
            this.checkVentilationRate();
        },
        selectedAge(){
            this.checkVentilationRate();
        },
        selectedAgeHelp() {
            this.checkForUvalues();
            this.checkVentilationRate();
        },
        customUvalues() {
            this.checkForUvalues();
        },
        customAirChange() {
            this.checkVentilationRate();
        },
        selectedZoneHelp() {
            if(this.pickedEmitter.length > 0){
                this.selectedZone = this.pickedEmitter.find(x => x.id === this.selectedZoneHelp);
                this.checkForUvalues();
            }
        },
        numOfInternalWalls() {
            if (!Number.isInteger(this.numOfInternalWalls)) {
                this.numOfInternalWalls = Math.round(this.numOfInternalWalls);
            }
            if (this.beforeValue1 > this.numOfInternalWalls) {
                for (let i = 0; i < this.beforeValue1 - this.numOfInternalWalls; i++) {
                    this.internalWalls.pop();
                }
            } else {
                for (let i = 0; i < this.numOfInternalWalls - this.beforeValue1; i++) {
                    this.internalWalls.push();
                }
            }

            this.beforeValue1 = this.numOfInternalWalls;
        },
        numOfExternalWalls() {
            if (!Number.isInteger(this.numOfExternalWalls)) {
                this.numOfExternalWalls = Math.round(this.numOfExternalWalls);
            }
            if (this.beforeValue2 > this.numOfExternalWalls) {
                for (let i = 0; i < this.beforeValue2 - this.numOfInternalWalls; i++) {
                    this.externalWalls.pop();
                }
            } else {
                for (let i = 0; i < this.numOfExternalWalls - this.beforeValue2; i++) {
                    this.externalWalls.push();
                }
            }

            this.beforeValue2 = this.numOfExternalWalls;
        },
        numOfWindows() {
            if (!Number.isInteger(this.numOfWindows)) {
                this.numOfWindows = Math.round(this.numOfWindows);
            }
            if (this.beforeValue3 > this.numOfWindows) {
                this.windows.pop();
                this.windowSurfaceA.pop();
            } else {
                this.windows.push();
                this.windowSurfaceA.push();
            }

            this.beforeValue3 = this.numOfWindows;
        },
        numOfDoors() {
            if (!Number.isInteger(this.numOfDoors)) {
                this.numOfDoors = Math.round(this.numOfDoors);
            }
            if (this.beforeValue4 > this.numOfDoors) {
                this.doors.pop();
            } else {
                this.doors.push();
            }

            this.beforeValue4 = this.numOfDoors;
        },
        roofLights() {
            if (!Number.isInteger(this.roofLights)) {
                this.roofLights = Math.round(this.roofLights);
            }
            if (this.beforeValue5 > this.roofLights) {
                this.lights.pop();
            } else {
                this.lights.push();
            }

            this.beforeValue5 = this.roofLights;
        },
        chimneys() {
            if (!Number.isInteger(this.chimneys)) {
                this.chimneys = Math.round(this.chimneys);
            }
        },
        openFlues() {
            if (!Number.isInteger(this.openFlues)) {
                this.openFlues = Math.round(this.openFlues);
            }
        },
        extractionFans() {
            if (!Number.isInteger(this.extractionFans)) {
                this.extractionFans = Math.round(this.extractionFans);
            }
        },
        roomNumber(){
            if(this.i === this.roomNumber) {
                this.checkStore();
            }
        }
    },
    components: {
        roomType,
        svgFile
    }
}
</script>

<style lang="scss" scoped>
.buttonWrap {
    text-align: center;

    .btn {
        padding: 10px 25px;
        font-weight: 700;
        width: 240px;
    }
}

.accordion-header {
    .accordion-button {
        background-color: #EE7700;
        font-weight: 600;

        &:focus, &:active {
            color: black;
        }

        &:after {
            display: none;
        }
    }

    .accordion-button:not(.collapsed) {
        color: black;
    }
}

.accordion-body {
    background-color: #F9F9F9;

    p {
        font-weight: 700;

        .numberForData {
            padding: 8px 14px;
            border-radius: 50%;
            background-color: #EE7700;
            font-weight: 700;
            margin-right: 20px;

            @media only screen and (max-width: 575.98px) {
                padding: 6px 12px;
                margin-right: 6px;
            }
        }
    }

    .subSection {
        .paddingEl {
            @media only screen and (min-width: 1450px) {
                padding: 0 3em;
            }

        }


        h5 {
            font-size: 20px;
            padding: 0 15px 10px 3%;
            @media only screen and (max-width: 1199.98px) {
                padding: 0 15px 10px 2%;
            }
            @media only screen and (max-width: 991.98px) {
                padding: 0 15px 10px 0;
            }
            @media only screen and (max-width: 450px) {
                font-size: 18px;
            }
        }

        .buildingAgePadding {
            padding: 0 0 0 3%;
            @media only screen and (max-width: 1199.98px) {
                padding: 0 0 0 2%;
            }
            @media only screen and (max-width: 991.98px) {
                padding: 0;
            }
        }

        #uValues, #airChangeValue {
            margin-right: 10px;
        }

        label {
            font-weight: 600;
            margin-bottom: 0;
        }

        .lighterLabel {
            font-weight: 400;
        }

        .smaller2 {
            width: 120px;
        }

        .lineInput, .lineInput2 {
            position: relative;

            .smaller {
                width: 80px;
            }

            &:after {
                content: "";
                position: absolute;
                background-color: black;
                width: 1px;
                top: 0;
                height: 38px;
            }
        }

        .lineInput {
            &:after {
                left: 62px;
            }
        }

        .lineInput2 {
            &:after {
                left: 38px;
                @media only screen and (max-width: 991.98px) {
                    left: 40px;
                }
            }
        }

        .temp {
            &:after {
                right: 64px;
            }
        }

        input[type=number] {
            padding-right: 2rem;
            position: relative;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            opacity: 1;
            position: absolute;
            right: 5px;
            width: 16px;
            height: 28px;
            top: 5px;
        }

        .disabledInput {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            @media only screen and (max-width: 991.98px) {
                padding-top: 20px;
            }

            input {
                width: 100px;
                @media only screen and (max-width: 991.98px) {
                    margin: 0;
                    width: 100%;
                }
            }
        }

        .smaller {
            font-size: 16px;
        }

        .modalButton {
            font-size: 14px;
            padding: 8px;
            background-color: white;
            border: 1px solid #ced4da;
            box-shadow: 1px 1px 4px #ced4da;
        }

        .modalButton.clicked {
            border: 5px solid #EE7700;
            padding: 3px;
        }

        .modal {
            .btn-primary {
                color: white;
            }

            .modal-body {
                img {
                    display: block;
                    margin: auto;
                    max-width: 100%;
                    height: auto;
                }

                .input-group {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    @media only screen and (max-width: 520px) {
                        flex-direction: column;
                        flex: unset;
                    }

                    .form-control {
                        max-width: 100px;
                        border-radius: 0.25rem;
                        display: block;
                        margin: auto;
                        @media only screen and (max-width: 520px) {
                            max-width: 100%;
                            min-width: 80%;
                            margin-bottom: 20px;
                        }
                    }

                    label {
                        padding-left: 20px;
                        max-width: 140px;
                        text-align: center;
                        @media only screen and (max-width: 520px) {
                            max-width: 100%;
                            padding-bottom: 5px;
                        }
                    }

                    p {
                        text-align: center;
                    }
                }

                .roomShape {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media only screen and (max-width: 1199.98px) {
                        padding: 20px 0;
                    }

                    .form-check {
                        padding-left: 0.5rem;
                    }

                    label {
                        position: relative;
                        max-width: 100%;
                        padding: 0 0px 5px 0;
                        margin: 0 10px;

                        @media only screen and (max-width: 767.98px) {
                            max-width: 49px;
                        }

                        .form-check-input {
                            position: absolute;
                            width: 100%;
                            height: 94%;
                            top: -3px;
                            left: 27px;
                            background-color: transparent;
                            border: none;
                            border-radius: 0px;
                        }

                        .form-check-input:checked[type=radio] {
                            background-image: none;
                            box-shadow: 0 0 0 0.15rem rgb(238 119 0 / 75%);
                        }

                        img {
                            max-width: 48px;
                            height: auto;
                            padding: 4px;
                            background-color: white;
                            border-radius: 0.25rem;
                            box-shadow: 1px 1px 2px #dedede;
                            @media only screen and (max-width: 420px) {
                                max-width: 40px;
                                padding: 3px;
                            }
                        }
                    }

                    .lighterLabel {
                        max-width: 100%;
                    }
                }
            }
        }

        .roomDim {
            display: flex;
            align-items: center;
            height: fit-content;
            @media only screen and (max-width: 991.98px) {
                padding: 10px 0 10px 12px;
                justify-content: flex-start;
            }

            label {
                padding-right: 20px;
                @media only screen and (max-width: 991.98px) {
                    width: auto;
                }
                @media only screen and (max-width: 767.98px) {
                    width: 230px;
                }
                @media only screen and (max-width: 420px) {
                    font-size: 16px;
                    width: 230px;
                }
            }

            .dimLabel {
                width: 320px;

                @media only screen and (max-width: 767.98px) {
                    width: 200px;
                }
            }

            .form-control {
                width: 65px;
                @media only screen and (max-width: 991.98px) {
                    margin: 0 5px;
                }
            }

            .smallerLabel {
                font-size: 14px;
            }

            .form-check-input {
                margin-top: 2px;
            }

            .smaller2::-webkit-inner-spin-button,
            .smaller2::-webkit-outer-spin-button {
                display: none;
            }

            input[type=number] {
                padding-right: 0;
            }

        }
    }
}
</style>

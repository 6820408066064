<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
        }
    },
    mounted() {

    },
}
</script>

<style lang="scss">
@import "./assets/scss/style.scss";
#app{
    background-image: url("./assets/images/dot-grid.png");
    background-position: center;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    min-height: 100vh;
}

</style>

<template>
    <section class="report">
        <div class="container">
            <div class="customCard">
                <div class="imgWrap">
                    <img src="../assets/images/LOGO_NRG.png" alt="NRG awareness logo"/>
                </div>
                <h5>
                    Dear {{ data.lastName }},
                </h5>
                <p>The results of your heat loss calculations are ready. Please check your e-mail!</p>
                <div class="links pt-4">
                    <a href="https://app.nrgawareness.com/" class="btn btn-outline-primary me-2 me-sm-4">Back to step 1</a>
                    <a href="https://www.nrgawareness.com/" class="btn btn-primary">Home</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import store from "@/store";

export default {
    data() {
        return {
            data: null,
        }
    },
    methods: {

    },
    created() {
        if (store.getters.getAllData) {
            this.data = store.getters.getAllData;
        }
    },
}
</script>

<style lang="scss" scoped>
.report {
    padding: 60px 0;

    .customCard {
        width: 100%;
        margin: 30px auto;
        background-color: #f9f9f9;
        padding: 40px 20px;

        @media only screen and (max-width: 370px){
            padding: 40px 10px;
        }

        p {
            text-align: center;
        }

        .customText {
            padding: 10px 80px 40px 80px;
        }


        .imgWrap {
            text-align: center;

            img {
                max-width: 150px;
                height: auto;
                padding-bottom: 40px;
            }
        }

        h5 {
            text-align: center;
        }

        .links{
            text-align: center;
            a{
                width: 200px;

                @media only screen and (max-width: 575.98px){
                    width: 150px;
                }
            }
        }
    }
}

</style>

<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;">
        <circle class="st0" cx="50" cy="50" r="38.61"/>
        <path class="st1" d="M46.11,58.98l-0.12-1.56c-0.36-3.24,0.72-6.77,3.71-10.36c2.7-3.18,4.19-5.51,4.19-8.21
	c0-3.06-1.92-5.09-5.69-5.15c-2.16,0-4.55,0.72-6.05,1.86l-1.44-3.77c1.98-1.44,5.39-2.4,8.57-2.4c6.89,0,10.01,4.25,10.01,8.81
	c0,4.07-2.28,7.01-5.15,10.42c-2.64,3.12-3.59,5.75-3.42,8.81l0.06,1.56H46.11z M44.79,67.36c0-2.22,1.5-3.77,3.59-3.77
	c2.1,0,3.53,1.56,3.53,3.77c0,2.1-1.38,3.71-3.59,3.71C46.23,71.08,44.79,69.46,44.79,67.36z"/>
    </svg>
</template>


<style lang="scss" scoped>
svg {
    cursor: pointer;
    width: 24px;
    height: auto;
    margin-top: -4px;

    .st0 {
        fill: #E2E0E0;
    }

    .st1 {
        fill: #737373;
    }
}
</style>

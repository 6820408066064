<template>
    <div>
        <div class="clearSubSection">
            <div class="row justify-content-end">
                <button class="btn btn-outline-secondary" @click="deleteRoom" :disabled="i === 1 && numOfRooms === 1">
                    Delete room
                </button>
                <button class="btn btn-outline-primary" @click="clearRoomData">
                    Clear room data
                </button>
            </div>
        </div>
        <p>
            <span class="numberForData">1</span> Choose room type:
            <span data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Choose room type. This selection is used to determine room temperature and ventilation rate.">
                                <svg-file></svg-file>
                        </span>
        </p>
        <div class="roomWrap">
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#livingRoom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"livingRoom"+i' :value="rooms[5].id" :checked="idRoom === 6">
                    <img src="../assets/images/living-room.png" alt="Living room"/>
                    <p class="room">Living room</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#sittingRoom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"sittingRoom"+i' :value="rooms[6].id" :checked="idRoom === 7">
                    <img src="../assets/images/sitting-room.png" alt="Living room"/>
                    <p class="room">Sitting room</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#kitchen"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"kitchen"+i' :value="rooms[9].id" :checked="idRoom === 10">
                    <img src="../assets/images/kitchen.png" alt="Kitchen"/>
                    <p class="room">Kitchen</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#diningRoom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"diningRoom"+i' :value="rooms[4].id" :checked="idRoom === 5">
                    <img src="../assets/images/dining-room.png" alt="Dining room"/>
                    <p class="room">Dining room</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Bedroom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Bedroom"+i' :value="rooms[1].id" :checked="idRoom === 2">
                    <img src="../assets/images/bedroom.png" alt="Bedroom"/>
                    <p class="room">Bedroom</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Bathroom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Bathroom"+i' :value="rooms[0].id" :checked="idRoom === 1">
                    <img src="../assets/images/bathroom.png" alt="Bathroom"/>
                    <p class="room">Bathroom</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Toilet"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Toilet"+i' :value="rooms[15].id" :checked="idRoom === 16">
                    <img src="../assets/images/toilet.png" alt="Toilet"/>
                    <p class="room">Toilet</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Hallway"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Hallway"+i' :value="rooms[8].id" :checked="idRoom === 9">
                    <img src="../assets/images/hallway.png" alt="Hallway"/>
                    <p class="room">Hallway</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#gameRoom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"gameRoom"+i' :value="rooms[7].id" :checked="idRoom === 8">
                    <img src="../assets/images/game-room.png" alt="Game Room"/>
                    <p class="room">Game room</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Landing"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Landing"+i' :value="rooms[10].id" :checked="idRoom === 11">
                    <img src="../assets/images/stairway.png" alt="Landing"/>
                    <p class="room">Landing</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#utilityRoom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"utilityRoom"+i' :value="rooms[14].id" :checked="idRoom === 15">
                    <img src="../assets/images/utility-room.png" alt="Utility room"/>
                    <p class="room">Utility room</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Office"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Office"+i' :value="rooms[11].id" :checked="idRoom === 12">
                    <img src="../assets/images/office.png" alt="Office"/>
                    <p class="room">Office/Study</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#cloakRoom"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"cloakRoom"+i' :value="rooms[2].id" :checked="idRoom === 3">
                    <img src="../assets/images/cloakroom.png" alt="Cloak Room"/>
                    <p class="room">Cloak room</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Conservatory"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Conservatory"+i' :value="rooms[3].id" :checked="idRoom === 4">
                    <img src="../assets/images/conservatory.png" alt="Conservatory"/>
                    <p class="room">Conservatory</p>
                </label>
            </div>
            <div class="form-check roomType">
                <label class="form-check-label" :for='"#Store"+i'>
                    <input class="form-check-input" type="radio" :name='"radio"+i' v-model="idRoom"
                           :id='"Store"+i' :value="rooms[12].id" :checked="idRoom === 13">
                    <img src="../assets/images/storage.png" alt="Store"/>
                    <p class="room">Storage</p>
                </label>
            </div>
        </div>
        <div class="row pt-3" v-if="chosenRoom.temp !== null && chosenRoom.airChange !== null">
            <div class="col-md-12 ps-3">
                <input class="form-check-input" type="checkbox" v-model="customTemp" value="true" id="customTemp">
                <label class="form-check-label lighterLabel" for="customTemp">
                    Change temperature?
                </label>
            </div>
            <div class="col-md-12 ps-3 pt-3">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-3 col-md-6">
                        <label for="roomTemp">Room temperature</label>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <input type="number" class="form-control" id="roomTemp" v-model.number="chosenRoom.temp" min="0"
                               :disabled="!customTemp">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import roomsData from '../jsonFiles/rooms.json';
import store from "@/store";

export default {
    props: {
        i: {
            type: Number,
            required: true,
        },
        clearTypeData: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            rooms: roomsData,
            chosenRoom: {
                id: null,
                temp: null,
                airChange: null,
                roomName: '',
            },
            customTemp: false,
            idRoom: null,
        }
    },
    computed: {
        numOfRooms() {
            return store.getters.getNumOfRooms;
        }
    },
    methods: {
        clearRoomData() {
            this.idRoom = null;
            this.$emit('clearRoomData');
        },
        deleteRoom(){
            this.$emit('deleteRoom', this.i);
        }
    },
    mounted() {
        if (store.getters.getRoomData.length >= this.i) {
            let data = store.getters.getRoomData;
            this.idRoom = data[this.i - 1].chosenRoom.id;
        }
    },
    watch: {
        idRoom() {
            if (this.idRoom !== null && this.idRoom !== undefined) {
                this.chosenRoom = this.rooms.find(x => x.id === this.idRoom);
                this.$emit('pickedRoom', this.chosenRoom);
            } else {
                this.chosenRoom = {
                    id: null,
                    temp: null,
                    roomName: '',
                };
                this.rooms = roomsData;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
p {
    font-weight: 700;

    .numberForData {
        padding: 8px 14px;
        border-radius: 50%;
        background-color: #EE7700;
        font-weight: 700;
        margin-right: 20px;

        @media only screen and (max-width: 575.98px) {
            padding: 6px 12px;
            margin-right: 6px;
        }
    }
}

.clearSubSection {
    @media only screen and (max-width: 575.98px) {
        padding-bottom: 30px;
    }

    .btn {
        width: 175px;
        margin: 0 20px 0 0;
    }
}

.roomWrap {
    position: relative;
    padding: 15px 30px 0 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (max-width: 1199.98px) {
        padding: 15px 0 0 0;
    }
    @media only screen and (max-width: 991.98px) {
        text-align: center;
    }

    .roomType {
        flex: 0 1 calc(20% - 30px);
        @media only screen and (min-width: 1400px) {
            margin-right: 20px;
        }
        @media only screen and (max-width: 1199.98px) {
            flex: 0 1 calc(20% - 8px);
        }
        @media only screen and (max-width: 991.98px) {
            flex: 0 1 calc(30% - 8px);
        }
        @media only screen and (max-width: 767.98px) {
            padding-left: 0;
        }
        @media only screen and (max-width: 575.98px) {
            flex: 0 1 50%;
        }

        label {
            position: relative;
            margin-bottom: 0;

            .form-check-input {
                position: absolute;
                width: 100%;
                height: 94%;
                top: -5px;
                left: 27px;
                background-color: transparent;
                border: none;
                border-radius: 0px;
            }

            .form-check-input:checked[type=radio] {
                background-image: none;
                box-shadow: 0 0 0 0.25rem rgb(238 119 0 / 75%);
            }

            img {
                max-width: 120px;
                height: auto;
                padding: 15px;
                background-color: white;
                border-radius: 0.25rem;
                box-shadow: 1px 1px 2px #dedede;
                @media only screen and (min-width: 1200px) {
                    max-width: 140px;
                    padding: 20px;
                }
            }

            .room {
                //font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                font-size: 16px;
            }
        }
    }
}

.lighterLabel {
    padding-left: 20px;
}
</style>

import {
    createRouter,
    createWebHistory
} from 'vue-router';

import stepOne from "../views/stepOne";
import stepTwo from "../views/stepTwo";
import stepThree from "../views/stepThree";
import report from "../views/report";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../App.vue'),
        redirect: '/step-one'
    },
    {
        path: '/step-one',
        name: 'StepOne',
        component: stepOne,
    },
    {
        path: '/step-two',
        name: 'StepTwo',
        component: stepTwo,
    },
    {
        path: '/step-three',
        name: 'StepThree',
        component: stepThree,
    },
    {
        path: '/report',
        name: 'report',
        component: report,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

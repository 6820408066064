<template>
    <section class="stepOne">
        <div class="container">
            <div class="titleWrap">
                <img src="../assets/images/LOGO_NRG.png" alt="NRG awareness logo"/>
                <h1>Free Heat Loss Calculator App</h1>
            </div>
            <form>
                <div class="customCard">
                    <h2 class="text-center text-primary pb-5">Step 1</h2>
                    <div class="row d-flex align-items-center afterLine">
                        <div class="col-lg-12">
                            <p>
                                Whether you are updating a present heating system or starting from scratch, accurate
                                heat
                                calculations are vital. We at NRG Awareness have developed a free and user-friendly tool
                                to
                                help you select heating emitters, boilers and heat pumps for new-build or retrofit
                                heating
                                installations.
                            </p>
                            <p>
                                <strong>What you’ll need before you start</strong>
                                Have the necessary information ready before you start calculating!
                                <ul>
                                    <li>
                                        Type of heat source (boiler, heat pump etc.)
                                    </li>
                                    <li>
                                        Number of heating zones
                                    </li>
                                    <li>
                                        Type of heat emitters (radiators, underfloor heating, fan coils)
                                    </li>
                                    <li>
                                        Design flow and return temperatures for the heat emitters/zones.
                                    </li>
                                    <li>
                                        Insulation values (U-values) of all:
                                        <ul>
                                            <li>
                                                External walls, ground floor, roof, windows, and external doors.
                                            </li>
                                            <li>
                                                If the U-values are unknown, the building age can be used to estimate them.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Ceiling heights
                                    </li>
                                    <li>
                                        Shape of each room and length of all walls and if they are external or internal.
                                        <ul>
                                            <li>
                                                Room area can be prepared beforehand, but it can be calculated by the
                                                calculator if the length of walls and room shape is known.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Size and location of all windows
                                    </li>
                                    <li>
                                        Size and location of all external doors
                                    </li>
                                    <li>
                                        Number and location of:
                                        <ul>
                                            <li>Chimneys</li>
                                            <li>Open flues</li>
                                            <li>Extraction fans</li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                            <h2>Printable Checklist</h2>
                            <p>
                                We designed a handy printable checklist to help you gather the information needed to use the
                                Heat Loss Calculator here:
                            </p>
                            <div class="text-center pb-5">
                                <a class="btn btn-outline-primary"
                                   href="./images/nrg_checklist.pdf"
                                   download>Download checklist</a>
                            </div>
                           <p>
                               The final NRG Awareness Heat Loss Calculation Report package that will be sent to your e-mail address
                               includes:
                           </p>
                            <h2 class="smaller-title">Heat Loss Report</h2>
                            <ul>
                                <li>a detailed room-by-room heat loss report</li>
                            </ul>
                            <h2 class="smaller-title">NRG Awareness Offer</h2>
                            <ul>
                                <li>suggested materials to simplify the system design process for builders and contractors</li>
                            </ul>
                            <p class="disclaimer">
                                Disclaimer: The program uses conventional heat loss calculations. The results are dependent on accurate
                                user-data entry to calculate the heat losses of the building. The equations and numerical relationships
                                contained in this program are based on industry standards. This program is a design tool only; therefore,
                                all liability associated with design remains in the hands of the designer.
                            </p>
                            <p>Having trouble filling out the form? Check this link for the Manual:</p>
                            <div class="buttonWrap">
                                <a class="btn btn-primary"
                                   href="https://www.nrgawareness.com/products/product_item/nrg-awareness-heat-loss-calculator#cta_product"
                                   target="_blank" rel="noopener">Manual</a>
                            </div>
                        </div>
                    </div>
                    <div class="formPart">
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-6">
                                <label for="projectName" class="form-label">Project Name
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Name of your project">
                                        <svg-file></svg-file>
                                    </span>
                                </label>
                            </div>
                            <div class="col-sm-6 pt-3 pt-sm-0">
                                <input type="text" class="form-control" id="projectName" v-model="projectName" required>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="nameError">
                            <div class="col-12">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center pt-4 pt-sm-3">
                            <div class="col-lg-8 col-9">
                                <label for="outsideTemp" class="form-label">Outside Design Temperature
                                    <span data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Outside temperature during design conditions.
                                          Typical design conditions:
                                          Scotland & Island -5°C
                                          Northern England & Northern Ireland -4°C
                                          Midlands, Wales, Republic of Ireland -3°C
                                          London, south-west England -2°C
                                          Southern England -1°C">
                                        <svg-file></svg-file>
                                    </span>
                                </label>
                            </div>
                            <div class="col-lg-4 col-3 d-flex justify-content-end lineInput temp">
                                <input type="number" class="form-control smaller" id="outsideTemp" min="-10" max="10"
                                       v-model.number="outsideTemp" required> <span class="ps-2">&#8451;</span>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="tempError">
                            <div class="col-12">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="roomsError">
                            <div class="col-12">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center pt-3">
                            <div class="col-lg-8 col-9">
                                <label for="heatSource" class="form-label">Heat Source
                                </label>
                            </div>
                            <div class="col-lg-4 col-3 d-flex justify-content-end">
                                <select class="form-select heatSource"
                                        aria-label="Heat Source"
                                        name="heatSource"
                                        id="heatSource"
                                        v-model="pickedSource"
                                        required>
                                    <option selected disabled></option>
                                    <option v-for="heatSource in heatSources" :key="heatSource.id"
                                            :value="heatSource">
                                        {{ heatSource.source }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="sourceError">
                            <div class="col-12">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center pt-3">
                            <div class="col-lg-8 col-9">
                                <label for="numberOfRooms" class="form-label">How many heating zones does your project
                                    have?
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Enter how many heating zones your project has. An entire radiant underfloor heating manifold is classed as one heating zone. ">
                                        <svg-file></svg-file>
                                    </span>
                                </label>
                            </div>
                            <div class="col-lg-4 col-3 d-flex justify-content-end lineInput">
                                <input type="number" class="form-control smaller" id="numberOfZones" min="1" max="10" @input="num = $event.target.value.replace(/[^\d]/g,'');$event.target.value = num"
                                       v-model.number="numOfZones">
                            </div>
                        </div>
                        <div class="row mt-2" v-if="emitterError">
                            <div class="col-12">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row pt-4" v-for="index in numOfZones" :key="index">
                            <p>HEATING ZONE {{ index }}</p>
                            <div class="col-lg-6">
                                <div class="row d-flex align-items-center">
                                    <div class="col-md-6">
                                        <label for="heatingName" class="form-label lighterLabel">Custom name
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" id="heatingName"
                                               v-model="pickedEmitter[index-1].name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-3 pt-lg-0">
                                <div class="row d-flex align-items-center">
                                    <div class="col-md-6">
                                        <label :for='"heatEmmiter"+index' class="form-label lighterLabel">Heat Emitter
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <select class="form-select heatSource"
                                                aria-label="Heat Emmiter"
                                                name="heatEmmiter"
                                                :id='"heatEmmiter"+index'
                                                v-model="pickedEmitter[index-1].heatEmitter.id"
                                                required>
                                            <option selected disabled></option>
                                            <option v-for="emitter in heatEmitter" :key="emitter.id" :value="emitter.id">
                                                {{ emitter.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pt-3 d-flex align-items-center"
                                 v-if="pickedEmitter[index-1].heatEmitter.flow || pickedEmitter[index-1].heatEmitter.return">
                                <input class="form-check-input me-3" type="checkbox"
                                       v-model="pickedEmitter[index-1].heatEmitter.customValues" :value="true"
                                       :id='"flowAndReturn"+index'>
                                <label class="form-check-label lighterLabel pt-1" :for='"flowAndReturn"+index'>
                                    I know flow and return value
                                </label>
                            </div>
                            <div class="col-lg-6 pt-3" v-if="pickedEmitter[index-1].heatEmitter.flow">
                                <div class="row d-flex align-items-center">
                                    <div class="col-md-6">
                                        <label :for='"flow"+index' class="form-label lighterLabel">Flow
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="number" class="form-control" :id='"flow"+index'
                                               v-model.number="pickedEmitter[index-1].heatEmitter.flow"
                                               :disabled="pickedEmitter[index-1].heatEmitter.customValues===false">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-3" v-if="pickedEmitter[index-1].heatEmitter.return">
                                <div class="row d-flex align-items-center">
                                    <div class="col-md-6">
                                        <label :for='"return"+index' class="form-label lighterLabel">Return
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="number" class="form-control" :id='"return"+index'
                                               v-model.number="pickedEmitter[index-1].heatEmitter.return"
                                               :disabled="pickedEmitter[index-1].heatEmitter.customValues===false">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2" v-if="emitterError2[index-1]">
                                <p class="alert-danger p-2">{{ error }}</p>
                            </div>
                        </div>
                        <div class="row pt-5">
                            <div class="col-md-8">
                                <label>Does your project have a separate hot water zone?
                                    <span data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Does your project have a separate hot water zone?">
                                        <svg-file></svg-file>
                                    </span>
                                </label>
                            </div>
                            <div class="col-md-4 d-flex justify-content-center justify-content-md-end pt-3 pt-lg-0">
                                <div class="form-check pe-4">
                                    <input class="form-check-input" type="radio" name="hotWaterZone" id="hotWaterZone1"
                                           value="true" v-model="hotWaterZone"
                                           checked>
                                    <label class="form-check-label" for="hotWaterZone1">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="hotWaterZone" id="hotWaterZone2"
                                           value="false" v-model="hotWaterZone">
                                    <label class="form-check-label" for="hotWaterZone2">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttonWrap">
                    <button class="btn btn-primary" type="submit" @click.prevent="stepTwo">Next Step</button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import heatingSources from '../jsonFiles/heatingSources.json';
import store from "@/store";
//import axios from "axios";
import router from "@/router";
import * as bootstrap from "bootstrap";
import svgFile from '../components/svgFile'

export default {
    data() {
        return {
            heatSources: heatingSources,
            heatEmitter: [
                {
                    id: 1,
                    name: "Underfloor Heating",
                    customValues: null,
                    flow: null,
                    return: null
                },
                {
                    id: 2,
                    name: "Radiators",
                    customValues: null,
                    flow: null,
                    return: null
                },
                {
                    id: 3,
                    name: "Fan Coils",
                    customValues: null,
                    flow: null,
                    return: null
                },
                {
                    id: 4,
                    name: "Radiators and Fan Coil",
                    customValues: null,
                    flow: null,
                    return: null
                },
            ],
            projectName: '',
            pickedSource: [],
            pickedEmitter: [],
            outsideTemp: -3,
            numOfRooms: 1,
            numOfZones: 0,
            beforeValue: 0,
            hotWaterZone: true,

            nameError: false,
            emitterError: false,
            emitterError2: [],
            sourceError: false,
            tempError: false,
            roomsError: false,
            error: "This field is required"
        }
    },
    methods: {
        stepTwo() {
            const validation = this.pickedEmitter.every(item => item.name && item.heatEmitter);
            if (this.projectName && this.pickedEmitter.length && this.pickedSource.id !== undefined && this.outsideTemp && this.numOfRooms && this.hotWaterZone && validation) {
                store.dispatch('finishStepOne', {
                    projectName: this.projectName,
                    pickedSource: this.pickedSource,
                    pickedEmitter: this.pickedEmitter,
                    outsideTemp: this.outsideTemp,
                    numOfRooms: this.numOfRooms,
                    hotWaterZone: this.hotWaterZone
                });
                router.push({name: 'StepTwo'});
            } else {
                if (!this.projectName) {
                    this.nameError = true;
                } else {
                    this.nameError = false;
                }

                if (this.pickedSource.id === undefined) {
                    this.sourceError = true;
                } else {
                    this.sourceError = false;
                }

                if (!this.pickedEmitter.length) {
                    this.emitterError = true;
                } else {
                    this.emitterError = false;
                }

                if (!validation) {
                    for (let i = 0; i < this.pickedEmitter.length; i++) {
                        if (!this.pickedEmitter[i].name || !this.pickedEmitter[i].heatEmmiter.id) {
                            this.emitterError2[i] = true;
                        } else {
                            this.emitterError2[i] = false;
                        }
                    }
                } else {
                    for (let i = 0; i < this.pickedEmitter.length; i++) {
                        if (this.pickedEmitter[i].name && this.pickedEmitter[i].heatEmmiter.id) {
                            this.emitterError2[i] = false;
                        } else {
                            this.emitterError2[i] = true;
                        }
                    }
                }

                if (this.outsideTemp === null) {
                    this.tempError = true;
                } else {
                    this.tempError = false;
                }

                if (!this.numOfRooms) {
                    this.roomsError = true;
                } else {
                    this.roomsError = false;
                }
            }
        },
        checkFlowAndReturn() {
            if (this.pickedEmitter.length > 0) {
                for (let i = 0; i < this.numOfZones; i++) {
                    if(this.pickedEmitter[i].heatEmitter.id !== null){
                        const data = this.heatEmitter.find(el => el.id === this.pickedEmitter[i].heatEmitter.id);
                        this.pickedEmitter[i].heatEmitter.name = data.name;
                    }
                    if (this.pickedEmitter[i].heatEmitter.name !== undefined && this.pickedEmitter[i].heatEmitter.customValues === false) {
                        if (this.pickedEmitter[i].heatEmitter.name === "Underfloor Heating") {
                            this.pickedEmitter[i].heatEmitter.flow = 35;
                            this.pickedEmitter[i].heatEmitter.return = 30;
                        } else {
                            if (this.pickedSource.id === 3) {
                                this.pickedEmitter[i].heatEmitter.flow = 45;
                                this.pickedEmitter[i].heatEmitter.return = 40;
                            } else if (this.pickedSource.id === 1 || this.pickedSource.id === 2) {
                                this.pickedEmitter[i].heatEmitter.flow = 65;
                                this.pickedEmitter[i].heatEmitter.return = 50;
                            } else if (this.pickedSource.id === 4 || this.pickedSource.id === 5 || this.pickedSource.id === 6 || this.pickedSource.id === 7) {
                                this.pickedEmitter[i].heatEmitter.flow = 80;
                                this.pickedEmitter[i].heatEmitter.return = 70;
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })

        if (store.getters.getStepOneData) {
            let data = store.getters.getStepOneData;
            this.projectName = data.projectName;
            this.outsideTemp = data.outsideTemp;
            this.numOfRooms = data.numOfRooms;
            this.pickedSource = data.pickedSource;
            this.numOfZones = data.pickedEmitter.length;
            this.beforeValue = data.pickedEmitter.length;
            this.pickedEmitter = data.pickedEmitter;

            /*if(this.pickedEmitter){
                this.pickedEmitter.forEach(element => {
                    const emitter = this.heatEmitter.find(he => he.id === element.heatEmitter.id);
                    element.heatEmitter = this.heatEmitter[emitter];
                });

            }*/
            this.hotWaterZone = data.hotWaterZone;

            this.checkFlowAndReturn();
        }
    },
    watch: {
        numOfZones() {
            if(!Number.isInteger(this.numOfZones)){
                this.numOfZones = Math.round(this.numOfZones);
            }
            if (this.beforeValue > this.numOfZones) {
                for (let i = 0; i < this.beforeValue - this.numOfZones; i++) {
                    this.pickedEmitter.pop();
                }
            } else {
                for (let i = 0; i < this.numOfZones - this.beforeValue; i++) {
                    if(i === 0 && this.numOfZones - this.beforeValue === 1){
                        this.pickedEmitter.push({
                            name: '',
                            id: this.numOfZones,
                            heatEmitter: {
                                id: null,
                                name: '',
                                customValues: false,
                                flow: null,
                                return: null,
                            }
                        });
                    }else {
                        this.pickedEmitter.push({
                            name: '',
                            id: this.beforeValue + 1 + i,
                            heatEmitter: {
                                id: null,
                                name: '',
                                customValues: false,
                                flow: null,
                                return: null,
                            }
                        });
                    }
                }
            }

            this.beforeValue = this.numOfZones;
        },
        pickedSource() {
            this.checkFlowAndReturn();
        },
        pickedEmitter: {
            handler() {
                this.checkFlowAndReturn();
            },
            deep: true
        },
    },
    components: {
        svgFile
    }
}
</script>

<style lang="scss" scoped>
.stepOne {
    padding: 60px 0;

    .titleWrap {

        img {
            max-width: 200px;
            padding-bottom: 40px;
        }

        h1 {
            color: #EE7700;
            font-size: 32px;
        }
    }

    .disclaimer {
        font-size: 14px;
        font-style: italic;
    }

    .smaller-title {
        font-size: 20px;
    }

    form {
        display: block;
        margin: auto;


        .customCard {
            width: 80%;
            margin: 30px auto;
            padding: 50px 30px;
            background-color: #F9F9F9;

            @media only screen and (max-width: 1199.98px) {
                width: 90%;
            }
            @media only screen and (max-width: 991.98px) {
                width: 100%;
            }

            @media only screen and (max-width: 575.98px) {
                padding: 40px 20px;
            }

            @media only screen and (max-width: 349.98px) {
                padding: 40px 10px;
            }


            .afterLine {
                position: relative;
                padding-bottom: 90px;
                @media only screen and (max-width: 991.98px) {
                    padding-left: 0;
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: black;
                    width: 100%;
                    bottom: 70px;
                    height: 1px;
                    left: 0px;
                }
            }

            .formPart {
                padding: 0 60px;

                @media only screen and (max-width: 1199.98px) {
                    padding: 0 40px;
                }

                @media only screen and (max-width: 991.98px) {
                    padding: 0 20px;
                }

                @media only screen and (max-width: 575.98px) {
                    padding: 0;
                }
            }

            label {
                font-weight: 600;
                margin-bottom: 0;
            }

            .lighterLabel {
                font-weight: 400;
            }

            .lineInput {
                position: relative;

                .smaller {
                    width: 74px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: black;
                    width: 1px;
                    top: 0;
                    height: 100%;
                    right: 39px;
                }
            }

            .temp {
                &:after {
                    right: 64px;
                }
            }

            input[type=number] {
                padding-right: 2rem;
                position: relative;
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                opacity: 1;
                position: absolute;
                right: 5px;
                width: 16px;
                height: 28px;
                top: 5px;
            }
        }
    }

    .buttonWrap {
        text-align: center;

        .btn {
            padding: 10px 60px;
            font-weight: 700;
        }
    }
}

</style>

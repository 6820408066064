import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate'

export default createStore({
    state: {
        projectName: '',
        pickedSource: [],
        pickedEmitter: [],
        outsideTemp: -3,
        numOfRooms: 1,
        hotWaterZone: true,
        roomData: [],
        roomNum: 1,
        email: '',
        name: '',
        lastName: '',
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    getters: {
        getStepOneData(state) {
            let pickedEmitter = state.pickedEmitter;
            let numOfRooms = state.numOfRooms;
            let projectName = state.projectName;
            let outsideTemp = state.outsideTemp;
            let hotWaterZone = state.hotWaterZone;
            let pickedSource = state.pickedSource;
            return ({projectName, outsideTemp, hotWaterZone, pickedSource, pickedEmitter, numOfRooms});
        },
        getRoomNum(state) {
            let roomNum = state.roomNum;
            return (roomNum);
        },
        getNumOfRooms(state){
            let numOfRooms = state.numOfRooms;
            return (numOfRooms);
        },
        getRoomData(state) {
            let roomData = state.roomData;
            return (roomData);
        },
        getAllData(state) {
            let projectName = state.projectName;
            let pickedSource = state.pickedSource;
            let pickedEmitter = state.pickedEmitter;
            let numOfRooms = state.numOfRooms;
            let outsideTemp = state.outsideTemp;
            let hotWaterZone = state.hotWaterZone;
            let roomData = state.roomData;
            let email = state.email;
            let name = state.name;
            let lastName = state.lastName;
            return ({
                projectName,
                pickedSource,
                pickedEmitter,
                numOfRooms,
                outsideTemp,
                hotWaterZone,
                roomData,
                email,
                name,
                lastName
            })
        }
    },
    mutations: {
        STEP_ONE_FINISH(state, payload) {
            state.projectName = payload.projectName;
            state.pickedSource = payload.pickedSource;
            state.outsideTemp = payload.outsideTemp;
            if (state.numOfRooms > payload.numOfRooms) {
                if (state.roomData.length >= state.numOfRooms) {
                    for (let i = 0; i < state.numOfRooms - payload.numOfRooms; i++) {
                        state.roomData.pop();
                    }
                }
            }
            state.pickedEmitter = payload.pickedEmitter;
            state.numOfRooms = payload.numOfRooms;
            state.hotWaterZone = payload.hotWaterZone;
        },
        SAVE_ROOM_DATA(state, payload) {
            state.roomData[state.roomNum - 1] = payload;
        },
        SAVE_ROOM_NUM(state, roomNumber) {
            state.roomNum = roomNumber;
        },
        SAVE_PERSONAL_INFO(state, payload) {
            state.email = payload.email;
            state.name = payload.name;
            state.lastName = payload.lastName;
            state.roomNum = 1;
        },
        ADD_ROOM(state){
            state.numOfRooms+=1;
        },
        CLEAR_DATA(state) {
            state.roomData[state.roomNum - 1] = {
                chimneys: null,
                chosenRoom: {id: null, roomName: "", temp: null},
                doors: [],
                externalWalls: [],
                extractionFans: 0,
                heatedSpaceAbove: "",
                heatedSpaceBelow: "",
                height: 0,
                internalWalls: [],
                lights: [],
                openFlues: 0,
                roomLabel: "",
                selectedAge: {
                    id: null,
                    age: "",
                    roof: null,
                    wall: null,
                    floor: null,
                    windows: null,
                    doors: null
                },
                airChange: null,
                selectedZone: {
                    name: "",
                    heatEmitter: {
                        id: null, name: "",
                        customValues: false,
                        flow: null,
                        return: null
                    }
                },
                surfaceArea: null,
                windows: []
            };
        },
        DELETE_ROOM(state, i){
            state.roomData = state.roomData.filter((_, index) => index !== i - 1);
            state.numOfRooms -= 1;
        }
    },
    actions: {
        finishStepOne({commit}, payload) {
            commit('STEP_ONE_FINISH', {
                projectName: payload.projectName,
                pickedSource: payload.pickedSource,
                pickedEmitter: payload.pickedEmitter,
                outsideTemp: payload.outsideTemp,
                numOfRooms: payload.numOfRooms,
                hotWaterZone: payload.hotWaterZone
            });
        },
        saveRoomData({commit}, payload) {
            commit('SAVE_ROOM_DATA', payload);
        },
        saveRoomNum({commit}, roomNumber) {
            commit('SAVE_ROOM_NUM', roomNumber);
        },
        savePersonalInfo({commit}, payload) {
            commit('SAVE_PERSONAL_INFO', payload);
        },
        addRoom({commit}){
            commit('ADD_ROOM');
        },
        clearData({commit}) {
            commit('CLEAR_DATA');
        },
        deleteRoom({commit}, i) {
            commit('DELETE_ROOM', i);
        }
    },
    modules: {},
})
